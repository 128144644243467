import React from 'react';
import { NavItem } from 'components/AppSideNavigation/AppSideNavigation.types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';

export const useNavItems = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { userEmail, userPermissions, userPrefs } = useStoreSelector(selectAuthenticationState);

  const demoMode = userPrefs.PortalDemoMode === 'true';

  const navItems: NavItem[] = [
    {
      id: 'nav-home',
      icon: 'home',
      title: t('Home'),
      selected: history.location.pathname === '/home',
      onClick: () => {
        history.push('/home');
      },
    },
    {
      id: 'nav-profile',
      icon: 'account-card-details',
      title: t('Profile'),
      selected: history.location.pathname === '/profile',
      onClick: () => {
        history.push('/profile');
      },
    },
    {
      id: 'nav-groups',
      icon: 'account-group',
      title: t('Groups'),
      selected: history.location.pathname === '/groups',
      onClick: () => {
        history.push('/groups');
      },
    },
    {
      id: 'nav-stamps',
      icon: 'stamptool',
      title: t('Stamps'),
      selected: history.location.pathname === '/stamps',
      onClick: () => {
        history.push('/stamps');
      },
    },
    {
      id: 'nav-presets',
      icon: 'tune',
      title: t('Presets'),
      selected: history.location.pathname === '/presets',
      onClick: () => {
        history.push('/presets');
      },
    },
  ];

  if (userPermissions.includes('ManageCloudSettings')) {
    navItems.push({
      id: 'nav-settings',
      icon: 'settings-cog',
      title: t('Settings'),
      selected: history.location.pathname === '/settings',
      onClick: () => {
        history.push('/settings');
      },
    });
  }

  if (userPermissions.includes('ManagePacks')) {
    navItems.push({
      id: 'nav-packs',
      icon: 'package-variant-closed',
      title: t('Add-ons'),
      selected: history.location.pathname === '/add-ons',
      onClick: () => {
        history.push('/add-ons');
      },
    });
  }

  if (
    (userEmail.endsWith('@objective.com') ||
      userEmail === 'cloudtesting30+2@gmail.com' ||
      userEmail === 'pktesting83@gmail.com') &&
    !demoMode
  ) {
    navItems.push({
      id: 'nav-dashboard',
      icon: 'view-dashboard',
      title: t('Dashboard'),
      selected: history.location.pathname === '/dashboard',
      onClick: () => {
        history.push('/dashboard');
      },
    });
    if (userPermissions.includes('AuthorisePendingTrials')) {
      navItems.push({
        id: 'nav-trial-admin',
        icon: 'ticket-account',
        title: t('Trials'),
        selected: history.location.pathname === '/trials',
        onClick: () => {
          history.push('/trials');
        },
      });
    }
    if (userPermissions.includes('ViewLicenseCustomers') || userPermissions.includes('ManageLicenses')) {
      navItems.push({
        id: 'nav-customer-admin',
        icon: 'workgroup',
        title: t('Customers'),
        selected: history.location.pathname === '/customers',
        onClick: () => {
          history.push('/customers');
        },
      });
    }
    if (userPermissions.includes('ManageUserComms')) {
      navItems.push({
        id: 'nav-user-comms',
        icon: 'bullhorn',
        title: t('User Comms'),
        selected: history.location.pathname === '/usercomms',
        onClick: () => {
          history.push('/usercomms');
        },
      });
    }
  }

  return navItems;
};

export const useMinimizable = () => {
  const [isMinimised, setIsMinimised] = React.useState(true);
  const toggleMinimised = React.useCallback(() => {
    setIsMinimised((prevState) => !prevState);
  }, []);
  return {
    isMinimised,
    setIsMinimised,
    toggleMinimised,
  };
};
