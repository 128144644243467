import React from 'react';
import { Box, styled, useTheme } from '@material-ui/core';
import { GlobalHeader } from 'odl-components';
import Logo from 'components/Logo/Logo';
import { useTranslation } from 'react-i18next';
import UserNavItem from 'components/AppHeader/UserNavItem';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import ModeSelector from 'components/AppHeader/ModeSelector';

type Props = {
  fromTrapezeOnline?: boolean;
  lightTheme?: boolean;
  modeRef?: any;
};

const AppHeader: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();

  const fromTrapezeOnline = props.fromTrapezeOnline ? props.fromTrapezeOnline : false;

  return (
    <StyledGlobalHeader
      brandColour={theme.palette.objective.brandColors.trapeze.primary}
      logo={<Logo lightTheme={props.lightTheme} />}
      title={t('Trapeze Portal')}
      homeLink="/"
      a11yId="app-header"
      className={props.lightTheme ? 'light' : 'dark'}
    >
      <Box
        component="nav"
        position="absolute"
        top={0}
        right={0}
        display="flex"
        alignItems="center"
        height={theme.spacing(13)}
        paddingTop={theme.spacing(1)}
        paddingRight={theme.spacing(6)}
      >
        {fromTrapezeOnline && (
          <Box paddingRight={theme.spacing(6)}>
            <ModeSelector compact={!screenWidthMatch.sm} modeRef={props.modeRef} lightTheme={props.lightTheme} />
          </Box>
        )}
        <UserNavItem compact={!screenWidthMatch.sm} lightTheme={props.lightTheme} />
      </Box>
    </StyledGlobalHeader>
  );
};

export default AppHeader;

const StyledGlobalHeader = styled(GlobalHeader)(({ theme }) => ({
  '&.light': {
    backgroundColor: `${theme.palette.objective.light.white} !important`,
    '& #modeCurrent': {
      color: theme.palette.objective.dark.night,
    },
    '& icon': {
      color: theme.palette.objective.dark.night,
    },
    '& .MuiBox-root': {
      color: theme.palette.objective.dark.night,
    },
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: '#c99f51', // theme.palette.objective.brandColors.trapeze.primary too bright?
        color: theme.palette.objective.dark.night,
      },
    },
    '& .MuiPopover-root': {
      '& .MuiPaper-root': {
        backgroundColor: '#EDF1F5',
        color: theme.palette.objective.dark.night,
      },
    },
  },
}));
