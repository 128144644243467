import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box, Card, CardContent } from '@material-ui/core';

import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import { serviceContainer } from 'services/serviceContainer';

import EmailSection from './EmailSection';
import PasswordSection from './PasswordSection';
import VerificationScreen from './VerificationScreen';
import Spacer from 'components/Spacer/Spacer';
import InfoSection from './InfoSection';

const LoginScreen: React.FC = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const screenWidthMatch = useScreenWidthMatch();
  const [emailScreen, setEmailScreen] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [loginError, setLoginError] = useState<string>();
  const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);

  async function signIn(email: string, password: string) {
    setLoginError(undefined);
    try {
      await Auth.signIn(email, password).then(() => {
        history.push('/signin');
      });
    } catch (error: any) {
      setLoginError(error.message);
      if (error.message === 'User is not confirmed.') {
        setShowVerifyEmail(true);
      }
    }
  }

  const onEmailSubmit = (email: string) => {
    setEmail(email);
    let domain = email.split('@')[1].toLowerCase();
    let targetPage = query.get('path') || '';
    serviceContainer.cradle.licenseServer
      .getDomainFederated(domain)
      .then((response) => {
        if (response === true) {
          setTimeout(() => {
            Auth.federatedSignIn({ customProvider: domain, customState: targetPage }).catch((error) => {
              console.error('federatedSignIn error: ', error);
              history.push('/auth/signin');
            });
          });
        } else {
          setEmailScreen(false);
        }
      })
      .catch((error) => {
        console.error('Domain federation check error', error);
        setEmailScreen(false);
      });
  };

  const onPasswordSubmit = (password: string) => {
    if (password.length > 0) {
      signIn(email, password);
    } else {
      setLoginError('Password is a required field');
    }
  };

  const onEditEmail = () => {
    setEmailScreen(true);
    setLoginError(undefined);
  };

  const returnTo = (destination: string) => {
    setLoginError(undefined);
    setShowVerifyEmail(false);
    if (destination === 'login') {
      setEmail('');
    }
  };

  return (
    <Box width={screenWidthMatch.sm ? '55%' : '90%'} maxWidth="500px">
      <Card raised>
        <CardContent>
          {showVerifyEmail ? (
            <VerificationScreen email={email} returnTo={returnTo} />
          ) : (
            <Box>
              {email.length > 0 && !emailScreen ? (
                <PasswordSection
                  onPasswordSubmit={onPasswordSubmit}
                  email={email}
                  loginError={loginError}
                  onEditEmail={onEditEmail}
                />
              ) : (
                <EmailSection onEmailSubmit={onEmailSubmit} />
              )}
            </Box>
          )}
          <Spacer y={3} />
        </CardContent>
        <InfoSection />
      </Card>
    </Box>
  );
};

export default LoginScreen;
