import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';

import TrapezeLogoLarge from 'components/Logo/TrapezeLogoLarge';
import LandingOptions from 'components/LandingOptions/LandingOptions';
import LandingInformation from 'components/LandingInformation/LandingInformation';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { serviceContainer } from 'services/serviceContainer';
import { useStyles } from 'pages/landing/LandingPage.styles';

type Props = {
  children?: React.ReactNode;
};

const showLandingLinks = false;
// bottom links hidden until correct api is established

const LandingPage: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const [hasOnlineModeAccess, setHasOnlineModeAccess] = useState<boolean>(true);

  const getModes = useCallback(() => {
    // (end point to be confirmed)
    serviceContainer.cradle.licenseServer
      .getUserDetails()
      .then((response) => {
        if (response.ModesAllowedToUse.length > 0) {
          setHasOnlineModeAccess(true);
        } else {
          setHasOnlineModeAccess(false);
        }
      })
      .catch((error) => {
        console.error('Get mode error', error.response);
      });
  }, []);

  useEffect(() => {
    getModes();
  }, [getModes]);

  const { height } = useWindowDimensions();
  const bannerHeight = 106;
  return (
    <Box className={classes.pageContent}>
      <Container maxWidth="md">
        <Box display="flex" alignItems="flex-start" height={height}>
          <Grid container direction="column" alignItems="center">
            <Grid item id="ObjTrapeze_logo">
              <TrapezeLogoLarge />
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" justifyItems="middle" height={(height - bannerHeight) * 0.9}>
                <LandingOptions hasOnlineModeAccess={hasOnlineModeAccess} />
              </Box>
            </Grid>
            {showLandingLinks && (
              <Grid item>
                <Box display="flex" height={(height - 100) * 0.2}>
                  <LandingInformation />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
