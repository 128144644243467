import React, { useState } from 'react';
import { Box, Menu, MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { popoverAnchorOrigin } from 'components/AppHeader/UserNavItem.functions';
import Spacer from 'components/Spacer/Spacer';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';
import { useStyles } from 'components/AppHeader/UserNavItem.styles';
import AboutDialog from 'components/AboutDialog/AboutDialog';
import clsx from 'clsx';

type Props = {
  compact?: boolean;
  lightTheme?: boolean;
};

const UserNavItem: React.FC<Props> = ({ compact = false, lightTheme = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { userFirstName, userLastName } = useStoreSelector(selectAuthenticationState);
  const [showAboutDialog, setShowAboutDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickOpenMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickProfile = React.useCallback(() => {
    setAnchorEl(null);
    history.push('/profile');
  }, [history]);

  const handleClickAbout = () => {
    setAnchorEl(null);
    setShowAboutDialog(true);
  };

  const handleClickLogout = React.useCallback(() => {
    setAnchorEl(null);
    Auth.signOut().then(() => {
      history.push('/signout');
    });
  }, [history]);

  return (
    <Box data-testid="UserNavItem">
      <Box
        height={24}
        display="flex"
        alignItems="center"
        role="button"
        className={classes.userMenu}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClickOpenMenu}
      >
        <Box
          fontWeight={700}
          fontSize={14}
          width={18}
          height={18}
          color={theme.palette.objective.light.white}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="icon icon-account-circle"
        />
        {!compact && (
          <React.Fragment>
            <Spacer x={4} />
            <Box fontWeight={500} fontSize={14} color={theme.palette.objective.light.white}>
              {userFirstName.toUpperCase()} {userLastName.toUpperCase()}
              <Box component="span" className="icon icon-menu-down" />
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Menu
        id="user-menu"
        data-testid="user-menu"
        className={clsx(classes.menu, lightTheme ? 'light' : 'dark')}
        keepMounted={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={popoverAnchorOrigin}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleClickProfile} className={classes.menuItem}>
          <Box className="icon icon-account-card-details" paddingRight={theme.spacing(2)} />
          <Box>{t('Profile').toUpperCase()}</Box>
        </MenuItem>
        <MenuItem onClick={handleClickAbout} className={classes.menuItem}>
          <Box className="icon icon-information-outline" paddingRight={theme.spacing(2)} />
          <Box>{t('About').toUpperCase()}</Box>
        </MenuItem>
        <MenuItem onClick={handleClickLogout} className={classes.menuItem}>
          <Box className="icon icon-logout-variant" paddingRight={theme.spacing(2)} />
          <Box>{t('Logout').toUpperCase()}</Box>
        </MenuItem>
      </Menu>
      <AboutDialog open={showAboutDialog} onClose={() => setShowAboutDialog(false)} />
    </Box>
  );
};

export default UserNavItem;
