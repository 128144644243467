import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Menu, MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/Spacer/Spacer';
import { useStyles } from 'components/AppHeader/ModeSelector.styles';
import { useStoreDispatch, useStoreSelector } from 'store/hooks';
import { selectFlagFor, setFlag } from 'store/app-state/flag/flag';
import { serviceContainer } from 'services/serviceContainer';
import { UserModes } from 'services/license-server/LicenseServerService.types';
import clsx from 'clsx';
import readLogo from 'assets/images/read-icon.svg';
import './ModeSelector.css';
import { useHistory } from 'react-router-dom';

const AvailableOnlineModes = ['Read', 'Forms', 'Edit'];

type Props = {
  compact?: boolean;
  modeRef?: any;
  lightTheme?: boolean;
};

const ModeSelector: React.FC<Props> = (props, { compact = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useStoreDispatch();
  const history = useHistory();

  dispatch(
    setFlag({
      usedFor: 'Mode.Switch',
      flag: 'Read',
    })
  );

  const currentModeFlag = useStoreSelector((state) => selectFlagFor(state, { usedFor: 'Mode.Switch' }));

  const modeRef = useRef();

  const [availableModes, setAvailableModes] = useState<string[]>([]);
  const [currentMode, setCurrentMode] = useState<string>(currentModeFlag);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickOpenMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickRead = React.useCallback(() => {
    setAnchorEl(null);
    setCurrentMode('Read');
    dispatch(
      setFlag({
        usedFor: 'Mode.Changing',
        flag: 'Read',
      })
    );
  }, [dispatch]);

  const handleClickForms = React.useCallback(() => {
    setAnchorEl(null);
    setCurrentMode('Forms');
    dispatch(
      setFlag({
        usedFor: 'Mode.Changing',
        flag: 'Forms',
      })
    );
  }, [dispatch]);

  const handleClickEdit = React.useCallback(() => {
    setAnchorEl(null);
    setCurrentMode('Edit');
    dispatch(
      setFlag({
        usedFor: 'Mode.Changing',
        flag: 'Edit',
      })
    );
  }, [dispatch]);

  const getModes = useCallback(() => {
    serviceContainer.cradle.licenseServer
      .getUserDetails()
      .then((response) => {
        const OnlineModes = response.ModesAllowedToUse.filter((m) => AvailableOnlineModes.includes(m));
        if (OnlineModes.length < 1) {
          history.push('/');
        } else {
          setAvailableModes(OnlineModes);
          if (OnlineModes.length === 1) {
            dispatch(
              setFlag({
                usedFor: 'Mode.Changing',
                flag: OnlineModes[0],
              })
            );
          }
        }
      })
      .catch((error) => {
        console.error('Get mode error', error.response);
      });
  }, [history, dispatch]);

  useEffect(() => {
    getModes();
  }, [getModes]);

  return (
    <React.Fragment>
      {availableModes.length > 1 && (
        <Box data-testid="UserNavItem">
          <Box
            height={24}
            display="flex"
            alignItems="center"
            role="button"
            className={classes.userMenu}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClickOpenMenu}
          >
            <Spacer x={4} />
            {currentMode === 'Forms' && (
              <Box className="icon icon-form" color={theme.palette.objective.light.white} width="20px" />
            )}
            {currentMode === 'Edit' && (
              <Box className="icon icon-pencil" color={theme.palette.objective.light.white} width="20px" />
            )}
            {currentMode === 'Read' && (
              <img
                src={readLogo}
                alt="read-icon"
                className={props.lightTheme ? 'icon readicon-grey' : 'icon readicon-white'}
              />
            )}
            {!compact && currentMode && (
              <React.Fragment>
                <Spacer x={4} />
                <Box
                  id="modeCurrent"
                  fontWeight={500}
                  fontSize={14}
                  color={theme.palette.objective.light.white}
                  width="60px"
                >
                  {currentMode.toUpperCase()}
                  {availableModes.length > 1 && <Box component="span" className="icon icon-menu-down" />}
                </Box>
              </React.Fragment>
            )}
            <Spacer x={4} />
          </Box>
          {availableModes.length > 1 && (
            <Menu
              id="mode-menu"
              data-testid="mode-menu"
              className={clsx(classes.menu, props.lightTheme ? 'light' : 'dark')}
              ref={modeRef}
              keepMounted={true}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            >
              {availableModes.includes(UserModes.READ) && (
                <MenuItem
                  id="here"
                  onClick={handleClickRead}
                  className={classes.menuItem}
                  selected={currentMode === UserModes.READ}
                >
                  <img
                    src={readLogo}
                    alt="read-icon"
                    className={props.lightTheme ? 'icon readicon-grey' : 'icon readicon-white'}
                  />
                  <Spacer x={4} />
                  <Box>{t('Read ').toUpperCase()}</Box>
                  <Spacer x={4} />
                </MenuItem>
              )}
              {availableModes.includes(UserModes.FORMS) && (
                <MenuItem
                  id="Nope"
                  onClick={handleClickForms}
                  className={classes.menuItem}
                  selected={currentMode === UserModes.FORMS}
                >
                  <Spacer x={2} />
                  <Box className="icon icon-form" width="30px" />
                  <Spacer x={2} />
                  <Box>{t('Forms').toUpperCase()}</Box>
                  <Spacer x={4} />
                </MenuItem>
              )}
              {availableModes.includes(UserModes.EDIT) && (
                <MenuItem
                  id="here"
                  onClick={handleClickEdit}
                  className={classes.menuItem}
                  selected={currentMode === UserModes.EDIT}
                >
                  <Spacer x={2} />
                  <Box className="icon icon-pencil" width="30px" />
                  <Spacer x={2} />
                  <Box>{t('Edit').toUpperCase()}</Box>
                  <Spacer x={4} />
                </MenuItem>
              )}
            </Menu>
          )}
        </Box>
      )}
    </React.Fragment>
  );
};

export default ModeSelector;
