import React from 'react';
import { CardContent, Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AuthUI.styles';

const InfoSection: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <CardContent className={classes.termsContent}>
      <Grid container direction="row">
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open('https://www.objective.com', '_blank', 'noreferrer')}
          >
            <Typography>{t('Objective Corporation')}</Typography>
          </Link>
        </Grid>
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              window.open('https://www.objective.com.au/terms-of-service-objective-trapeze', '_blank', 'noreferrer')
            }
          >
            <Typography>{t('Terms of service')}</Typography>
          </Link>
        </Grid>
        <Grid container item xs justifyContent="center">
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open('https://www.objective.com.au/privacy-policy', '_blank', 'noreferrer')}
          >
            <Typography>{t('Privacy Policy')}</Typography>
          </Link>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default InfoSection;
