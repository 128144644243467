import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iconBorder: {
    borderBottomColor: '#B0c5c9',
    borderBottom: '4px solid',
  },
  activeIconBorder: {
    borderBottomColor: '#f1ac3d',
    borderBottom: '4px solid',
  },
  optionText: {
    fontWeight: 700,
    fontSize: 18,
    color: '#B0c5c9',
  },
  activeOptionText: {
    fontWeight: 700,
    fontSize: 18,
    color: 'white',
  },
  optionHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
