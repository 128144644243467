import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  optionHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionText: {
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: 18,
    },
  },
  activeText: {
    color: theme.palette.objective.light.white,
  },
  activeIcon: {
    color: theme.palette.objective.brandColors.trapeze.primary,
  },
  inactive: {
    // color: '#B0c5c9',
    color: theme.palette.objective.blue.light,
  },
  optionGrid: {
    '& .optionBlock': {
      height: 260,
      textAlign: 'center',
      borderBottom: '4px solid',
      marginBottom: '20px',
    },
    '& .MuiBox-root.icon': {
      fontSize: 120,
    },
  },
}));
