import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button, ButtonTypes } from 'odl-components';
import TrapezeIllustration from 'assets/images/Group7.svg';
import Spacer from 'components/Spacer/Spacer';
import { useStyles } from './AuthUI.styles';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  segment: string;
}

const InformationPage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const onLearnClick = () => {
    window.open('https://www.objective.com/products/objective-trapeze', '_blank', 'noopener,noreferrer');
  };

  return (
    <Grid container direction="column" className={classes.fullHeight} alignContent="center" justifyContent="center">
      <Grid container direction="column" className={classes.gridWidth}>
        {props.segment === 'purchase' ? (
          <React.Fragment>
            {width > 1900 ? (
              <Grid item>
                <Typography className={classes.header} align="center">
                  {t('To purchase Objective Trapeze you will need an Objective ID account.')}
                </Typography>
                <Spacer y={3} />
                <Typography className={classes.header} align="center">
                  {t("Please create a new account if you don't have one already then sign in to continue.")}
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Typography className={classes.header} align="center">
                  {t(
                    "To purchase Objective Trapeze you will need an Objective ID account. Please create a new account if you don't have one already then sign in to continue."
                  )}
                </Typography>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item>
            <Typography className={classes.header} align="center">
              {t('A smarter way to assess and approve digital plans')}
            </Typography>
          </Grid>
        )}
        <Spacer y={6} />
        <Grid container item justifyContent="center">
          <img src={TrapezeIllustration} alt="trapeze-team-img" width="50%" />
        </Grid>
        <Spacer y={8} />
        <Grid item>
          <Typography align="center">
            {t(
              'Efficiently and accurately replace your paper or digital PDF processes by using the only purpose-built software to measure, assess, annotate and stamp digital plans and drawings.'
            )}
          </Typography>
        </Grid>
        <Spacer y={4} />
        <Grid container item justifyContent="center">
          <Button
            a11yId="button-learn-more"
            type={ButtonTypes.PRIMARY}
            text={t('Learn more')}
            onClickHandler={onLearnClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationPage;
