import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './AuthUI.styles';

import Spacer from 'components/Spacer/Spacer';

interface Props {
  label: string;
  required?: boolean;
}

const FieldLabel: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Grid container item direction="row" className={classes.label}>
      <Grid item>
        <Typography>{props.label}</Typography>
      </Grid>
      {props.required && (
        <React.Fragment>
          <Spacer x={1} />
          <Grid item>
            <Typography className="asterisk">{'*'}</Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default FieldLabel;
