import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menu: {
    '&.dark': {
      '& .MuiListItem-root:hover': {
        backgroundColor: '#c99f51', // theme.palette.objective.brandColors.trapeze.primary too bright?
        color: theme.palette.objective.dark.night,
      },
    },
    '&.light': {
      '& .Mui-focusVisible': {
        background: 'none',
      },
      '& .MuiListItem-root:hover': {
        backgroundColor: theme.palette.objective.blue.light,
        color: theme.palette.objective.dark.night,
      },
    },
    '& .MuiListItem-root': {
      minWidth: 192,
      lineHeight: '36px',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '&.MuiPopover-root.light': {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.objective.light.white,
        color: theme.palette.objective.dark.night,
        border: '1px solid' + theme.palette.objective.dark.night,
      },
    },
    '&.MuiPopover-root.dark': {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.objective.dark.night,
        color: theme.palette.objective.light.white,
      },
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#c99f51',
      color: '#32373f',
    },
  },
  userMenu: {
    cursor: 'pointer',
  },
}));
