import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  infoGrid: {
    backgroundColor: theme.palette.objective.light.white,
  },
  loginGrid: {
    backgroundColor: theme.palette.objective.brandColors.trapeze.web,
  },
  fullHeight: {
    height: '100vh',
  },
  maxWidth: {
    width: '80%',
  },
  controllerHeight: {
    minHeight: '100vh',
  },
  gridWidth: {
    width: '80%',
  },
  header: {
    fontSize: '22px',
    lineHeight: '28px',
  },
  label: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 500,
      '&.asterisk': {
        color: theme.palette.objective.red.main,
      },
    },
  },
  iconSpace: {
    paddingRight: '4px',
  },
  disabledField: {
    background: theme.palette.objective.light.mid,
  },
  showHide: {
    '&.icon-eye': {
      fontSize: '18px',
    },
    cursor: 'pointer',
  },
  errorText: {
    color: theme.palette.objective.red.main,
  },
  termsContent: {
    background: theme.palette.objective.light.day,
    padding: 18,
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: 500,
      color: theme.palette.objective.dark.fiordland,
    },
    '&.MuiCardContent-root': {
      paddingBottom: 18,
    },
  },
}));
