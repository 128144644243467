import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { Button, ButtonTypes } from 'odl-components';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as EmailValidator from 'email-validator';

import VerificationScreen from './VerificationScreen';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import Spacer from 'components/Spacer/Spacer';
import FieldLabel from './FieldLabel';
import { useStyles } from './AuthUI.styles';
import InfoSection from './InfoSection';

const NewAccount = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const screenWidthMatch = useScreenWidthMatch();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);

  // eslint-disable-next-line
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

  async function signUp() {
    await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    })
      .then(() => {
        setUserCreated(true);
      })
      .catch((error: any) => {
        setSignUpError(null);
        setEmailError(null);
        if (error.message === 'Username should be an email.' || error.message === 'Username cannot be empty') {
          setEmailError(t('Email should be a valid email address'));
        } else if (error.message === 'An account with the given email already exists.') {
          setEmailError(error.message);
        } else if (error.message.toLowerCase().includes('password')) {
          setPasswordError(
            t(
              'Password should contain at least 8 characters with an uppercase letter, lowercase letter a number and a special character.'
            )
          );
        } else {
          setSignUpError(error.message);
        }
      });
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setSignUpError(null);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setSignUpError(null);
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') signUp();
  };

  const onNextClick = () => {
    let error = false;
    setEmailError(null);
    setPasswordError(null);
    if (email.length === 0) {
      setEmailError(t('Email address cannot be empty'));
      error = true;
    }
    if (EmailValidator.validate(email) === false) {
      setEmailError(t('Email address is not valid'));
      error = true;
    }
    if (password.length === 0) {
      setPasswordError(t('Password cannot be empty'));
      error = true;
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        t(
          'Password should contain at least 8 characters with an uppercase letter, lowercase letter a number and a special character.'
        )
      );
    }
    if (firstName.length === 0) {
      setFirstNameError(t('Given name cannot be empty'));
      error = true;
    } else {
      setFirstNameError(null);
    }
    if (lastName.length === 0) {
      setLastNameError(t('Family name cannot be empty'));
      error = true;
    } else {
      setLastNameError(null);
    }

    if (!error) {
      signUp();
    }
  };

  const onSignInClick = () => {
    history.push('/auth/signin');
  };

  useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-email')?.focus();
  }, []);

  return (
    <Box width={screenWidthMatch.sm ? '55%' : '90%'} maxWidth="500px">
      <Card>
        <CardContent>
          {userCreated ? (
            <VerificationScreen email={email} />
          ) : (
            <Grid container direction="column" item xs justifyContent="center">
              <Grid item>
                <Typography className={classes.header} align="center">
                  {t('Create new account')}
                </Typography>
              </Grid>
              <Spacer y={4} />
              <Grid item>
                <Typography align="center">{t('To create a new account, enter your details below.')}</Typography>
              </Grid>
              <Spacer y={4} />
              <FormControl fullWidth>
                <Grid container item justifyContent="flex-start">
                  <FieldLabel required label={t('Email')} />
                </Grid>
                <Spacer y={2} />
                <Grid item>
                  <TextField
                    id="textfield-email"
                    autoComplete="off"
                    fullWidth
                    placeholder="Your work email address"
                    onChange={onEmailChange}
                    variant="outlined"
                    error={emailError !== null}
                    InputProps={{
                      startAdornment: <Box className={clsx('icon icon-email-outline', classes.iconSpace)} />,
                    }}
                  />
                </Grid>
                {emailError && (
                  <FormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {emailError}
                  </FormHelperText>
                )}
                <Spacer y={4} />
                <Grid container item justifyContent="flex-start">
                  <FieldLabel required label={t('Given name')} />
                </Grid>
                <Spacer y={2} />
                <Grid item>
                  <TextField
                    id="textfield-firstName"
                    autoComplete="off"
                    fullWidth
                    onChange={onFirstNameChange}
                    variant="outlined"
                    error={firstNameError !== null}
                  />
                </Grid>
                {firstNameError && (
                  <FormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {firstNameError}
                  </FormHelperText>
                )}
                <Spacer y={4} />
                <Grid container item justifyContent="flex-start">
                  <FieldLabel required label={t('Family name')} />
                </Grid>
                <Spacer y={2} />
                <Grid item>
                  <TextField
                    id="textfield-lastName"
                    autoComplete="off"
                    fullWidth
                    onChange={onLastNameChange}
                    variant="outlined"
                    error={lastNameError !== null}
                  />
                </Grid>
                {lastNameError && (
                  <FormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {lastNameError}
                  </FormHelperText>
                )}
                <Spacer y={4} />
                <Grid item>
                  <Grid container item justifyContent="flex-start">
                    <FieldLabel required label={t('Password')} />
                  </Grid>
                  <Spacer y={2} />
                  <TextField
                    id="textfield-password"
                    type="password"
                    fullWidth
                    placeholder="Password"
                    onChange={onPasswordChange}
                    onKeyPress={onKeyPress}
                    variant="outlined"
                    error={passwordError !== null}
                  />
                </Grid>
                {passwordError && (
                  <FormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {passwordError}
                  </FormHelperText>
                )}
                <Spacer y={4} />
              </FormControl>
              <Grid item>
                {signUpError && (
                  <Grid item>
                    <Typography className={classes.errorText}>{signUpError}</Typography>
                    <Spacer y={4} />
                  </Grid>
                )}
                <Button
                  a11yId="button-next"
                  type={ButtonTypes.PRIMARY}
                  text={t('next')}
                  fullWidth
                  onClickHandler={onNextClick}
                />
                <Spacer y={2} />
              </Grid>
              <Spacer y={2} />
              <Grid container item direction="row">
                <Grid item>
                  <Typography>{t('Already have an account?')}</Typography>
                </Grid>
                <Spacer x={2} />
                <Grid item>
                  <PointerLink onClick={onSignInClick}>
                    <Typography>{t('Sign in')}</Typography>
                  </PointerLink>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <InfoSection />
      </Card>
    </Box>
  );
};

export default NewAccount;

const PointerLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});
