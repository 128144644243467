import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.33333 24" height="100" width="350" id="svg2" version="1.1">
      <defs id="defs6">
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
          <path id="path16" d="M 0,18 H 142 V 0 H 0 Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,24)" id="g10">
        <g id="g12">
          <g clipPath="url(#clipPath18)" id="g14">
            <g transform="translate(0.0928,2.377)" id="g20">
              <path
                id="path22"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="m 0,0 c -0.046,-0.092 0.046,-0.231 0.139,-0.231 h 3.384 c 0.139,0 0.232,0.092 0.278,0.185 l 5.053,15.437 c 0.047,0.093 -0.046,0.232 -0.139,0.232 H 5.331 c -0.139,0 -0.232,-0.093 -0.278,-0.185 z"
              />
            </g>
            <g transform="translate(22.437,2.377)" id="g24">
              <path
                id="path26"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="m 0,0 c 0.046,-0.092 -0.046,-0.231 -0.139,-0.231 h -3.384 c -0.139,0 -0.232,0.092 -0.278,0.185 l -5.007,15.437 c -0.046,0.093 0.047,0.232 0.139,0.232 h 3.384 c 0.139,0 0.232,-0.093 0.279,-0.185 z"
              />
            </g>
            <g transform="translate(12.0532,17.9536)" id="g28">
              <path
                id="path30"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="m 0,0 c 0.185,0 0.278,-0.139 0.324,-0.325 l 2.782,-8.483 c -0.603,1.576 -2.133,2.689 -3.94,2.689 -1.762,0 -3.292,-1.113 -3.941,-2.689 l 2.781,8.483 C -1.947,-0.139 -1.854,0 -1.669,0 Z"
              />
            </g>
            <g transform="translate(95.8682,13.9204)" id="g32">
              <path
                id="path34"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 V -11.451 H -1.205 V 0 H -3.616 V 1.066 H 2.41 L 2.41,0 Z"
              />
            </g>
            <g transform="translate(104.7686,2.4697)" id="g36">
              <path
                id="path38"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 -2.271,5.795 H -3.894 V 0 H -5.1 v 12.517 h 3.014 c 0.417,0 0.788,-0.046 1.205,-0.185 0.371,-0.093 0.742,-0.279 1.021,-0.557 0.277,-0.278 0.555,-0.602 0.741,-1.02 C 1.066,10.338 1.159,9.828 1.159,9.226 1.159,8.716 1.112,8.252 0.974,7.835 0.835,7.464 0.649,7.14 0.464,6.861 0.231,6.583 0,6.398 -0.278,6.259 -0.557,6.12 -0.788,6.027 -1.066,5.98 L 1.391,0 Z m -0.093,9.133 c 0,0.464 -0.046,0.834 -0.185,1.113 -0.139,0.277 -0.279,0.556 -0.51,0.695 -0.186,0.185 -0.464,0.278 -0.695,0.371 -0.279,0.092 -0.557,0.092 -0.834,0.092 H -3.894 V 6.722 h 1.623 c 0.649,0 1.205,0.186 1.576,0.603 0.417,0.464 0.602,1.066 0.602,1.808"
              />
            </g>
            <g transform="translate(113.4844,2.4697)" id="g40">
              <path
                id="path42"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 -0.742,3.106 H -4.59 L -5.378,0 H -6.63 l 3.199,12.517 h 1.669 L 1.344,0 Z M -2.643,11.404 H -2.689 L -4.404,4.126 h 3.476 z"
              />
            </g>
            <g transform="translate(121.9209,11.6025)" id="g44">
              <path
                id="path46"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="m 0,0 c 0,-0.556 -0.093,-1.066 -0.278,-1.53 -0.186,-0.463 -0.417,-0.788 -0.696,-1.112 -0.278,-0.278 -0.602,-0.51 -0.973,-0.65 -0.37,-0.138 -0.741,-0.231 -1.158,-0.231 H -4.96 V -9.086 H -6.165 V 3.431 h 2.967 c 0.37,0 0.741,-0.047 1.112,-0.139 0.371,-0.093 0.695,-0.279 1.02,-0.557 C -0.741,2.457 -0.51,2.132 -0.324,1.669 -0.093,1.159 0,0.649 0,0 m -1.205,-0.046 c 0,0.417 -0.047,0.741 -0.14,1.066 -0.092,0.278 -0.231,0.556 -0.463,0.742 -0.185,0.185 -0.417,0.324 -0.649,0.417 -0.231,0.092 -0.51,0.139 -0.788,0.139 h -1.669 v -4.821 h 1.669 c 0.557,0 1.02,0.185 1.437,0.603 0.417,0.417 0.603,1.019 0.603,1.854"
              />
            </g>
            <g transform="translate(123.126,2.4697)" id="g48">
              <path
                id="path50"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 V 12.517 H 5.007 V 11.404 H 1.206 V 7.047 H 4.775 V 5.98 H 1.206 V 1.113 H 5.192 V 0 Z"
              />
            </g>
            <g transform="translate(129.709,2.4697)" id="g52">
              <path
                id="path54"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 V 1.066 L 4.59,11.404 H 0.232 v 1.113 H 5.888 V 11.404 L 1.345,1.113 H 5.934 V 0 Z"
              />
            </g>
            <g transform="translate(136.7559,2.4697)" id="g56">
              <path
                id="path58"
                fill="#f1ac3d"
                fillRule="nonzero"
                d="M 0,0 V 12.517 H 5.053 V 11.404 H 1.205 V 7.047 H 4.821 V 5.98 H 1.205 V 1.113 H 5.238 L 5.238,0 Z"
              />
            </g>
            <g transform="translate(56.1855,5.6689)" id="g60">
              <path
                id="path62"
                fill="white"
                d="m 0,0 c 0,-1.437 0.695,-3.384 3.894,-3.384 3.152,0 3.894,1.947 3.894,3.384 V 2.411 H 4.636 v -2.642 c 0,-0.418 0,-0.974 -0.742,-0.974 -0.742,0 -0.742,0.556 -0.742,0.974 V 5.98 c 0,0.417 0,0.974 0.742,0.974 0.742,0 0.742,-0.557 0.742,-0.974 V 4.218 h 3.152 v 1.53 C 7.788,7.186 7.046,9.132 3.894,9.132 0.742,9.132 0,7.186 0,5.748 V 0"
              />
            </g>
            <g transform="translate(64.9009,4.6025)" id="g64">
              <path
                id="path66"
                fill="white"
                fillRule="nonzero"
                d="M 0,0 C 0,-1.9 1.066,-2.133 2.411,-2.133 H 3.152 V 7.649 H 3.94 v 2.364 H 3.152 v 3.06 H 0 v -3.06 H -0.788 V 7.649 H 0 V 0"
              />
            </g>
            <path
              id="path68"
              fill="white"
              fillRule="evenodd"
              d="m 69.305,2.424 h 3.152 v 12.192 h -3.152 z m 0,13.026 h 3.152 v 2.225 h -3.152 z"
            />
            <g transform="translate(74.8213,2.4238)" id="g70">
              <path
                id="path72"
                fill="white"
                fillRule="evenodd"
                d="M 0,0 H 3.709 L 5.841,12.192 H 2.688 L 1.901,4.404 H 1.854 l -0.741,7.788 h -3.245 z"
              />
            </g>
            <g transform="translate(47.7485,5.6689)" id="g74">
              <path
                id="path76"
                fill="white"
                fillRule="evenodd"
                d="m 0,0 c 0,-1.437 0.695,-3.384 3.894,-3.384 3.152,0 3.894,1.947 3.894,3.384 V 1.298 H 4.636 v -1.529 c 0,-0.418 0,-0.974 -0.742,-0.974 -0.742,0 -0.742,0.556 -0.742,0.974 v 2.595 h 4.59 v 3.338 c 0,1.437 -0.696,3.384 -3.894,3.384 -3.153,0 -3.894,-1.947 -3.894,-3.384 l 0,-5.702 z m 3.199,5.98 c 0,0.417 0,0.974 0.741,0.974 0.742,0 0.742,-0.557 0.742,-0.974 V 4.636 H 3.245 V 5.98 Z"
              />
            </g>
            <g transform="translate(80.5703,5.6689)" id="g78">
              <path
                id="path80"
                fill="white"
                fillRule="evenodd"
                d="m 0,0 c 0,-1.437 0.695,-3.384 3.894,-3.384 3.152,0 3.894,1.947 3.894,3.384 V 1.298 H 4.636 v -1.529 c 0,-0.418 0,-0.974 -0.742,-0.974 -0.696,0 -0.696,0.556 -0.696,0.974 v 2.595 h 4.59 V 5.702 C 7.788,7.139 7.046,9.086 3.894,9.086 0.741,9.086 0,7.139 0,5.702 Z m 3.152,5.98 c 0,0.417 0,0.974 0.696,0.974 0.741,0 0.741,-0.557 0.741,-0.974 V 4.636 H 3.152 Z"
              />
            </g>
            <g transform="translate(29.2056,13.8276)" id="g82">
              <path
                id="path84"
                fill="white"
                fillRule="evenodd"
                d="M 0,0 C 0,0.51 0,1.391 0.973,1.391 1.947,1.391 1.947,0.51 1.947,0 v -7.556 c 0,-0.417 0,-1.391 -0.974,-1.391 C 0,-8.947 0,-7.973 0,-7.556 Z m -3.384,-7.232 c 0,-2.688 1.669,-4.31 4.357,-4.31 2.689,0 4.358,1.576 4.358,4.31 v 6.908 c 0,2.967 -1.854,4.311 -4.358,4.311 -2.503,0 -4.357,-1.344 -4.357,-4.311 z"
              />
            </g>
            <g transform="translate(38.5234,5.4375)" id="g86">
              <path
                id="path88"
                fill="white"
                fillRule="evenodd"
                d="m 0,0 c 0,-0.418 0,-0.974 0.742,-0.974 0.741,0 0.741,0.556 0.741,0.974 v 6.211 c 0,0.418 0,0.974 -0.741,0.974 C 0,7.185 0,6.629 0,6.211 Z M 0,-2.782 V -3.014 H -3.152 V 12.238 H 0 V 9.039 c 0.231,0.186 0.695,0.325 0.974,0.325 2.966,0 3.662,-1.947 3.662,-3.385 V 0.231 c 0,-1.437 -0.649,-3.383 -3.57,-3.383 C 0.788,-3.106 0.231,-3.014 0,-2.782"
              />
            </g>
            <g transform="translate(43.9009,0.1523)" id="g90">
              <path
                id="path92"
                fill="white"
                fillRule="nonzero"
                d="m 0,0 h 0.742 c 2.225,0 2.41,1.345 2.41,3.198 V 14.463 L 0,14.463 V 0"
              />
            </g>
            <path id="path94" fill="white" fillRule="nonzero" d="m 43.901,17.675 h 3.152 V 15.45 h -3.152 z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
