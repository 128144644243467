import { createAction, createReducer } from '@reduxjs/toolkit';
import { IPrefs } from 'services/license-server/LicenseServerService.types';
import { RootState } from 'store/types';

// Type

export type AuthenticationState = {
  authenticating: boolean;
  authenticated: boolean;
  emailVerified: boolean;
  federatedLogin: boolean;
  userUniqueId: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPermissions: string[];
  errorMessage: string;
  userPrefs: IPrefs;
};

// Constants

export enum UserCanFlags {
  AUTHORISEPENDINGTRIALS = 'AuthorisePendingTrials',
  MANAGELICENSES = 'ManageLicenses',
  MANAGESTAMPS = 'ManageCloudStamps',
  MANAGEPRESETS = 'ManageCloudPresets',
  MANAGEPACKS = 'ManagePacks',
  MANAGECUSTOMERRESOURCES = 'ManageCustomerResources',
}

// Actions & Thunks

export const clearAuthenticationError = createAction('appState/authentication/clearError');
export const setAuthenticated = createAction<boolean>('appstate/authentication/setAuthenticated');
export const setEmailVerified = createAction<boolean>('appstate/authentication/setEmailVerified');
export const setFederatedLogin = createAction<boolean>('appstate/authentication/setFederatedLogin');
export const setUserEmail = createAction<string>('appstate/authentication/setUserEmail');
export const setUserFirstName = createAction<string>('appstate/authentication/setUserFirstName');
export const setUserLastName = createAction<string>('appstate/authentication/setUserLastName');
export const setUserPermissions = createAction<string[]>('appstate/authentication/setUserPermissions');
export const setUserPrefs = createAction<object>('appstate/authentication/setUserPrefs');
export const setUserUniqueId = createAction<string>('appstate/authentication/setUserUniqueId');

// Reducer

export const defaultAuthenticationState: AuthenticationState = {
  authenticating: false,
  authenticated: false,
  emailVerified: false,
  federatedLogin: false,
  userUniqueId: '',
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userPermissions: [],
  errorMessage: '',
  userPrefs: {
    TrapezeOnlineDemoMode: 'false',
    PortalTheme: 'light',
    PortalDemoMode: 'false',
    StampDefaultView: 'menu',
  },
};

export const authenticationReducer = createReducer<AuthenticationState>(defaultAuthenticationState, (builder) => {
  builder
    .addCase(clearAuthenticationError, (state, action) => {
      state.errorMessage = '';
    })
    .addCase(setAuthenticated, (state, action) => {
      state.authenticated = action.payload;
    })
    .addCase(setEmailVerified, (state, action) => {
      state.emailVerified = action.payload;
    })
    .addCase(setFederatedLogin, (state, action) => {
      state.federatedLogin = action.payload;
    })
    .addCase(setUserEmail, (state, action) => {
      state.userEmail = action.payload;
    })
    .addCase(setUserFirstName, (state, action) => {
      state.userFirstName = action.payload;
    })
    .addCase(setUserLastName, (state, action) => {
      state.userLastName = action.payload;
    })
    .addCase(setUserPermissions, (state, action) => {
      state.userPermissions = action.payload;
    })
    .addCase(setUserPrefs, (state, action) => {
      state.userPrefs = action.payload;
    })
    .addCase(setUserUniqueId, (state, action) => {
      state.userUniqueId = action.payload;
    });
});

// Selectors

export const selectAuthenticationState = (state: RootState) => {
  return state.appState.authentication;
};
