import * as awilix from 'awilix';
import { asClass, asFunction, asValue } from 'awilix';
import { developmentLogger } from 'services/logger/developmentLogger';
import { productionLogger } from 'services/logger/productionLogger';
import { IS_DEV_ENV, IS_TEST_ENV } from 'constants/configs';
import { Cradle } from 'services/serviceContainer.types';
import { I18nInitializer } from 'services/i18n/I18nInitializer';
import { makeI18n } from 'services/i18n/makeI18n';
import { I18nInitializerMock } from 'services/i18n/I18nInitializer.mock';
import LicenseServerService from 'services/license-server/LicenseServerService';
import IPLookupService from 'services/ipapi-co/IPLookupService';

const serviceContainer = awilix.createContainer<Cradle>({
  injectionMode: awilix.InjectionMode.PROXY,
});

// Register default services
serviceContainer.register({
  i18n: asFunction(makeI18n).singleton(),
  i18nInitializer: asClass(I18nInitializer).singleton(),
  licenseServer: asClass(LicenseServerService).singleton(),
  ipLookup: asClass(IPLookupService).singleton(),
  logger: asValue(productionLogger),
});

// Register services on test env
if (IS_TEST_ENV) {
  serviceContainer.register({
    i18nInitializer: asClass(I18nInitializerMock).singleton(),
  });
}

// Register services on development env
if (IS_DEV_ENV) {
  serviceContainer.register({
    logger: asValue(developmentLogger),
  });
}

export { serviceContainer };
