import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '20px',
  },
  logo: {
    color: theme.palette.objective.brandColors.trapeze.web,
    '& .icon-objective-logo-trapeze': {
      fontSize: '9em',
    },
    margin: '50px 80px 50px 50px',
  },
  dialogLight: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.objective.light.white,
      '& .icon-window-close': {
        color: theme.palette.objective.dark.night,
      },
    },
  },
  lineHeight: {
    lineHeight: '1.4em',
  },
}));
