import { createTheme } from '@material-ui/core/styles';
import { objectivePalette } from 'styles/objectivePalette';
import ObjectiveWOFF from 'assets/fonts/Objective.woff';

// prettier-ignore
const standardFontFamily = [
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  "Arial",
  "Helvetica",
  "sans-serif",
].join(",");

const standardTypography = {
  fontFamily: standardFontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1,
};

export const defaultTheme = createTheme({
  // Color palette
  palette: {
    objective: objectivePalette,
    primary: {
      main: objectivePalette.system.primary,
    },
    error: {
      main: objectivePalette.red.main,
    },
    text: {
      primary: objectivePalette.dark.night,
      secondary: objectivePalette.dark.neutral,
    },
    background: {
      default: objectivePalette.light.mid,
      paper: objectivePalette.light.white,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // iPad portrait
      md: 1024, // iPad landscape
      lg: 1280, // laptop (mackbook)
      xl: 1440, // desktop
      xxl: 1600, // desktop
      xxxl: 1920, // desktop (design)
    },
  },
  // Spacing - based on ODL factor4
  spacing: (factor: number): string | number => `${factor * 4}px`,
  // Typography
  typography: {
    // Font family - based on ODL
    fontFamily: standardFontFamily,
    // Normalize typography - separate styles from semantic
    h1: standardTypography,
    h2: standardTypography,
    h3: standardTypography,
    h4: standardTypography,
    h5: standardTypography,
    h6: standardTypography,
    subtitle1: standardTypography,
    subtitle2: standardTypography,
    body1: standardTypography,
    body2: standardTypography,
    htmlFontSize: 14,
  },
  overrides: {
    // Normalize typography - separate styles from semantic
    MuiCssBaseline: {
      '@global': {
        h1: {
          margin: 0,
        },
        h2: {
          margin: 0,
        },
        h3: {
          margin: 0,
        },
        h4: {
          margin: 0,
        },
        h5: {
          margin: 0,
        },
        h6: {
          margin: 0,
        },
        p: {
          margin: 0,
        },
        '@font-face': [
          {
            fontFamily: 'Objective',
            fontStyle: 'normal',
            fontDisplay: 'block',
            fontWeight: 'normal',
            src: `url(${ObjectiveWOFF}) format('woff')`,
          },
        ],
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiListItem: {
      button: {
        '&:active, &:hover': {
          color: objectivePalette.system.primary,
        },
      },
    },
  },
});
