import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divlines: {
    borderTop: '1px solid',
    color: '#B0c5c9',
  },
  icon: {
    paddingRight: 5,
  },
  infoHeader: {
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
  },
  infoLinks: {
    fontWeight: 350,
    fontSize: 14,
    color: '#B0c5c9',
    padding: 8,
    textAlign: 'center',
    '&:hover': {
      color: 'white',
      cursor: 'pointer',
    },
  },
  vertDividers: {
    color: '#B0c5c9',
    borderLeft: '1px solid',
    borderRight: '1px solid',
  },
  bannerContent: {
    width: 300,
    height: 250,
    textAlign: 'center',
  },
}));
