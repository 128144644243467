export enum AssignItemTarget {
  GROUP = 1,
  USER,
}

export enum ResourceType {
  GROUP = 'Group',
  PACK = 'Pack',
  PRESET = 'Preset',
  STAMP = 'Stamp',
  STAMPMENU = 'StampMenu',
}

export enum StampMenuItemType {
  MENU = 1,
  TEXTSTAMP,
  PICTURESTAMP,
  MULTISTAMP,
  UNKNOWNSTAMP, // should be the last entry
}

export enum StampMenuShareOption {
  NOTSET = 'NotSet',
  SHARECOPY = 'ShareCopy',
  DONTSHARE = 'DontShare',
  SHARE = 'Share',
}

export enum UserCanPermission {
  PRESETS = 'ManageCloudPresets',
  SETTINGS = 'ManageCloudSettings',
  STAMPS = 'ManageCloudStamps',
  PACKS = 'ManagePacks',
}

export enum UserModes {
  READ = 'Read',
  FORMS = 'Forms',
  EDIT = 'Edit',
  CLASSIC = 'Classic',
}

export enum TrapezePermission {
  MIGRATIONADMIN = 'MigrationAdmin',
  MIGRATIONTEST = 'CloudSharingTest',
  CLOUDSTAMPS = 'CloudStamps',
  CLOUDPRESETS = 'CloudPresets',
}

export interface IAddCustomerRequest {
  ResourceId: string;
  ResourceIds: string[] | null;
  Email: string | null;
  Emails: string[] | null;
  Group: string | null;
  ResourceType: string;
  RoleForUser: string;
}

export interface IAddCustomerMultiRequest {
  ResourceIds: string[];
  Emails: string[] | null;
  Group: string | null;
  ResourceType: string;
  RoleForUser: string;
}

export interface IAddLicenseSubscriptionAdmin {
  LicenseId: number;
  AdminEmail: string;
}

export interface IAddToStampMenuRequest {
  MenuSharingOptions: string;
  StampMenuId: string;
  Email: string | null;
  Group: string | null;
  Role: string;
}

export interface IAdminResourceRequest {
  Stamps: string[];
  StampMenus: string[];
  Presets: string[];
  customerId: string;
}

export interface IAssignedUser {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsPendingUser: boolean;
}

export interface IAssignSettingsRequest {
  SettingSetId: string;
  Email: string;
  GroupId: string;
}

export interface IBillingAddress {
  First_Name: string | null;
  Last_Name: string | null;
  Email: string | null;
  Line1: string | null;
  Line2: string | null;
  Line3: string | null;
  City: string | null;
  State_Code: string | null;
  State: string | null;
  Country: string;
  Zip: string | null;
  Validation_Status: string | null;
}

export interface IBlockedRecources {
  PresetIds?: string[];
}

export interface IConsumerPackData {
  SubscriptionId: string;
  Email: string;
  GroupId: string;
  PackId: string;
}

export interface IHighScores {
  daily?: { score: number; date: string };
  weekly?: { score: number; date: string };
  monthly?: { score: number; date: string };
}

export interface ICreateCustomer {
  Name: string;
  Description: string | null;
  Country: string;
  Vetted: boolean;
  Domain: string;
  SFDCAccountRef?: string;
}

export interface ICreateLicence {
  Name: string;
  Location: string;
  MaxUsers: number;
  ExpiryDate: Date;
  IsSiteLicense: boolean;
  Trial: boolean;
  SendMachineInfo: boolean;
  IDP: boolean;
  AdminEmail: string;
  CloudSharing: boolean;
  Integration: string[];
}

export interface ICustomer {
  Id: string;
  OrganizationName: string | null;
  Phone: string | null;
  BillingAddress: IBillingAddress | null;
  SFDCAccountRef: string;
}

export interface ICustomerUser {
  id: string;
  UserCan: string[];
  ClientPrefs: {};
  FirstName: string;
  LastName: string;
  Email: string;
  StampVersion: string;
  UseDefaultCalculations: boolean;
  CalculationVersion: string;
  PresetVersion: string;
  AssignedSettingsId: string;
  SettingsVersion: string;
  Groups: string[];
  GroupLevels: {};
  UploadedData: string[];
  TrapezePermissions: string[];
  PendingUser: boolean;
}

export interface IDataRequest {
  CustomerCount?: string;
  DailyUserCountFor?: string;
  DailyUserCountFrom?: string;
  DailyUserCountTo?: string;
  DailyUserSummaryBy?: string;
  DailyUserSummaryFilter?: string;
  DailyUserSummaryFrom?: string;
  DailyUserSummaryTo?: string;
  LicenseCount?: string;
  OSVersionCounts?: string;
  ProductUseFor?: string;
  ProductUseForVersion?: string;
  ProductUseFrom?: string;
  ProductUseTo?: string;
  UsageForLicense?: string;
  UsageForLicenseFrom?: string;
  UsageForLicensetype?: string;
  UserCountFor?: string;
  UserCountFrom?: string;
  UserCountTo?: string;
}

export interface IDataResponse {
  DailyUserSummary?: object[];
  LicenseUserCount?: object;
}

export interface IFederatedResponse {
  Domain: string;
  IsFederated: boolean;
}

export interface IGroup {
  Id: string;
  Name: string;
  Level: number; // this is a priority/precendence
  Groups: string[];
  CurrentUserRole: number;
  AssociatedSettingSetId: string;
  AssociatedSettingSetName: string;
  AssignedPacks: IPackInfo[];
  FederationGroupId: string;
}

export interface IGroupCreate {
  Name: string;
  Level: number; // this is a priority/precendence
  FederationGroupId: string;
}

export interface IGroupUpdate {
  Id: string;
  Name: string;
  Level: number; // this is a priority/precendence
  FederationGroupId: string;
}

export interface ILicenseOptions {
  Description: string;
  DefaultValue: string;
  Name: string;
  Type: string;
  InclusiveMin: number;
  InclusiveMax: number;
}

export interface IInvitation {
  id: string;
  CreationTime: number;
  CreatorId: string;
  CreatorName: string;
  ResourceId: string;
  ResourceType: string;
  ResourceLabel: string;
  Status: string;
  Email: string;
  Role: string;
  CreatedUsingOverride: boolean;
}

export interface IInviteAccept {
  Accept: boolean;
}

export interface ILicense {
  Id: number;
  SubscriptionId: string;
  Name: string;
  ProductName: string;
  CreatedWhen: string;
  FirstUsed: string;
  ExpiresWhen: string;
  MaxUserCount: number;
  TargetUserCount: number;
  IsTrial: boolean;
  IsPerpetual: boolean;
  IsTrapezeProfessional: boolean;
  IsSiteLicense: boolean;
  HasExpired: boolean;
  HasAlert: boolean;
  IDP: boolean;
  CloudStamps: boolean;
  CloudPresets: boolean;
}

export interface IFilters {
  search: string;
  status: string;
  idp: string;
  vetted: string;
  cloudFeature: string;
  countryCode: string;
  primary: boolean;
}

export interface ILicenseAuditItem {
  Sender: string;
  DateSent: string;
  SentTo: string;
}

export interface ILicenseCustomer {
  id: string;
  name: string;
}

export interface ILicenseCustomerDetails {
  Id: number;
  Name: string;
  Description: string;
  Country: string;
  Vetted: boolean;
  Domain: string;
  IsDomainFederated: boolean;
  PrimaryLicenseId: number | null;
  Licenses: ILicense[];
  SFDCAccountRef: string;
}

export interface ILicenseCustomerFilter {
  countryCode?: string;
  status?: string;
  vetted?: boolean;
  idp?: boolean;
  cloudFeature?: boolean;
  primaryOnly?: boolean;
}

export interface ILicenseCustomersResponse {
  customers: ILicenseCustomer[];
  count: number;
}

export interface ILicenseEmailDetails {
  Email: string;
  FirstName: string;
  Organisation: string;
  BccEmails: string[];
}

export interface ILinkedSetting {
  SelectedValue: number;
  RegKey: string;
  Action: string;
  Value: number;
}

export interface IPack {
  id: string;
  Name: string;
  Description: string;
  Version: string;
  Modified: string;
  LicenseAcknowledgement: string;
  StampIds: string[];
  StampMenuIds: string[];
  CalculationSetIds: string[];
  PresetIds: string[];
  OverlayIds: string[];
  OverlayMenuIds: string[];
  Purchased: string;
}

export interface IPackInfo {
  Name: string;
  id: string;
}

export interface IPackRequest {
  SubscriptionId: string;
  Email: string;
  GroupId: string;
  PackId: string;
}

export interface IPackSelection {
  id: string;
  Name: string;
  Description: string;
  Version: string;
  Modified: string;
  StampIds: string[];
  StampMenuIds: string[];
  CalculationSetIds: string[];
  PresetIds: string[];
  OverlayIds: string[];
  OverlayMenuIds: string[];
}

export interface IPaymentSourceLink {
  Id: string;
  HostedPageType: number;
  Url: string;
  State: number;
  FailureReason: number;
  PassThruContent: {} | null;
  Embed: boolean;
  CreatedAt: string;
  ExpiresAt: string;
  UpdatedAt: string;
  ResourceVersion: number;
  CheckoutInfo: {} | null;
  Content: {} | null;
}

export interface IPaymentSourceUpdateRequest {
  CustomerId: string;
}

export interface IPreset {
  id: string;
  Type: string;
  Name: string;
  Tag: string;
  MultipleUsers: boolean;
  Manager: boolean;
  UseOnly: boolean;
  BlockedInTrapeze: boolean;
}

export interface IRoleDefinition {
  role: string;
  label: string;
  description: string;
  description_plural?: string;
}

export interface IServerPreset {
  id: string;
  PresetType: string;
  Name: string;
  Tag: string;
  PresetText: string;
  Flags: number;
  AnnotationString: string;
  Version: string | null;
  MultipleUsers: boolean;
  CurrentUserRole: number;
  BlockedInTrapeze: boolean;
}

export interface IPresetServerData {
  Presets: IServerPreset[];
  Version: string;
  CustomerResources: boolean;
}

export interface IPresetData {
  Presets: IPreset[];
  Version: string;
  CustomerResources: boolean;
}

export interface ISubPack {
  id: string;
  name: string;
  packs: IPack[];
}

export interface ISubs {
  id: string;
  Name: string;
}

export interface ISubscription {
  id: string;
  TimeStamp: number;
  ChargebeeSubscriptionId?: string;
  ChargebeeCustomerId?: string;
  TrapezeCustomerId?: string;
  Name: string;
  Status: string;
  StatusMessage: string;
  IsTrial: boolean;
  CanBeCancelled: boolean;
  CanChangeUsers: boolean;
  IsSiteLicense: boolean;
  ExpiresWhen: string;
  RenewalWhen: string;
  LicenseCount: number | null;
  CurrentUserRole: string;
}

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IServerStamp {
  id: string;
  Name: string;
  Type: string;
  FileName: string;
  IniConfig: string;
  MultipleUsers: boolean;
  CurrentUserRole: number;
  Version: string;
}

export interface IServerStampMenu {
  id: string;
  Name: string;
  Children: string[];
  IsRootMenu: boolean;
  MultipleUsers: boolean;
  CurrentUserRole: number;
  Version: string;
  IsShareable: boolean;
}

export interface IStamp {
  id: string;
  Name: string;
  Type: string;
  MenuPath: string;
  MultipleUsers: boolean;
  Manager: boolean;
  UseOnly: boolean;
  CustomerResources: boolean;
}

export interface IStampMenu {
  id: string;
  Name: string;
  Type: StampMenuItemType;
  MenuPath: string;
  Children: IStampMenu[];
  MultipleUsers: boolean;
  Manager: boolean;
  UseOnly: boolean;
  parent?: string;
  IsShareable?: boolean;
}

export interface IStampData {
  Stamps: IStamp[];
  Menus: IStampMenu[];
  CustomerResources: boolean;
}

export interface IStampImageUrl {
  FileName: string;
  Url: string;
}

export interface IStampServerData {
  Stamps: IServerStamp[];
  Menus: IServerStampMenu[];
  Urls: IStampImageUrl[];
  Version: string;
  CustomerResources: boolean;
}

export interface ISubscriptionCancelRequest {
  SubscriptionId: string;
}

export interface ISubscriptionChangeRequest {
  SubscriptionId: string;
  PlanQuantity: number;
}

export interface ISubscriptionEstimateRequest {
  PlanId: string;
  PlanQuantity: number;
  BillingAddressCountry: string;
}

export interface ISubscriptionEstimate {
  CreatedAt: string;
  SubscriptionEstimate: {
    Id: string | null;
    CurrencyCode: string;
    Status: number;
    NextBillingAt: string;
    PauseDate: string | null;
    ResumeDate: string | null;
    ShippingAddress: string | null;
    ContractTerm: string | null;
  };
  InvoiceEstimate: {
    Recurring: boolean;
    PriceType: number;
    CurrencyCode: string;
    SubTotal: number;
    Total: number;
    CreditsApplied: number;
    AmountPaid: number;
    AmountDue: number;
    LineItems: {}[];
    Discounts: {} | null;
    Taxes: {}[];
    LineItemTaxes: {}[];
    LineItemTiers: {} | null;
    LineItemDiscounts: {}[];
    RoundOffAmount: number;
    CustomerId: string;
  };
  InvoiceEstimates: {} | null;
  NextInvoiceEstimate: {} | null;
  CreditNoteEstimates: {} | null;
  UnbilledChargeEstimates: {} | null;
}

export interface INewInviteRequest {
  ResourceType: string;
  ResourceId: string;
  Email: string;
  Role: string;
  FlagsToAdd: string[];
}

export interface INewCustomerPurchaseRequest {
  PlanId: string;
  PlanQuantity: number;
  PayByCreditCard: boolean;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerEmail: string;
  CustomerPhone?: string;
  CustomerCompany?: string;
  BillingAddressCountry: string;
}

export interface IPermissionSetRequest {
  ResourceId: string;
  ConsumerId: string;
  Permissions: string[];
}

export interface IPrefs {
  TrapezeOnlineDemoMode?: string;
  PortalTheme?: string;
  PortalDemoMode?: string;
  StampDefaultView?: string;
  LicenseMonths?: string;
  TransferCopy?: string;
}

export interface IRoleUpdateRequest {
  NewRole: string;
}

export interface ISettingEnum {
  Value: string | number;
  Label: string;
}

export interface ISettingDefinition {
  Category: string;
  Section: string;
  Name: string;
  Description: string;
  IsVirtual: boolean;
  RegKey: string;
  ValueType: string;
  DefaultValue: string | number;
  IntegerMin: number;
  IntegerMax: number;
  ScaleFactor: number;
  StringLimit: number;
  DisplayHeight: number;
  DisplayFullWidth: boolean;
  HiddenText: boolean;
  EnumOptions: ISettingEnum[];
  LinkedSetting: ILinkedSetting[];
  ColourPresets: object;
}

interface ISettingSetBase {
  id: string;
  SubscriptionId: string;
  ParentSettingSetId: string;
  Name: string;
  Settings: { [key: string]: any };
  AdvancedSettings: string;
}

export interface ISettingSet extends ISettingSetBase {
  Files: { [key: string]: string };
  CurrentUserRole: number | null;
}

export interface ISettingSetInfo extends ISettingSetBase {
  Files: string[];
}

export interface ISettingsMigrationRequest {
  SettingsSetId: string | null;
  SubscriptionId: string;
  Name: string;
  ParentSettingSetId: string;
  IniFileContents: string;
  IniPresetContents: string;
  IniPrinterGroupsContents: string;
  IniOverlaysContents: string;
  IniPrintersContents: string;
  IniStampContents: string;
  IniExemptionCodeGroupContents: string[];
  ProcessStamps: boolean;
  ProcessPresets: boolean;
  ProcessSettingSetData: boolean;
  ProcessOverlays: boolean;
}

export interface ISettingsMigrationResponse {
  id: string;
  FilesToUpload: string[];
}

export interface ISubscriptionPurchaseLink {
  Id: string;
  HostedPageType: number;
  Url: string;
  State: number;
  FailureReason: number;
  PassThruContent: {} | null;
  Embed: boolean;
  CreatedAt: string;
  ExpiresAt: string;
  UpdatedAt: string;
  ResourceVersion: number;
  CheckoutInfo: {} | null;
  Content: {} | null;
}

export interface ITrialAssignee {
  Id: string;
  FirstName: string;
  LastName: string;
}

export interface ITrialRequest {
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Address: string;
  JobTitle: string;
  Organisation: string;
  Comments: string;
  Domain: string;
  Country: string;
  CampaignId: string;
}

export interface ITrial {
  id: string;
  State: string;
  Request: ITrialRequest;
  Assignee: ITrialAssignee;
  SalesforceId: string;
  ResolverId: string;
  CreationTime: number | null;
}

export interface ITrialApprove {
  Approve: boolean;
}

export interface IUserData {
  UserCan: string[];
  ClientPrefs: {};
  ModesAllowedToUse: string[];
}

export interface IUserProfile {
  Id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Role: string;
  Flags: string[];
  IsPendingUser: boolean;
  InheritedRole: string | null;
}

export interface IGroupProfile {
  Id: string;
  Name: string;
  DisplayName: string;
  Role: string;
  FederationGroupId: string;
}

export interface IResourceConsumers {
  Users: IUserProfile[];
  Groups: IGroupProfile[];
}

export interface ISubscriptionOption {
  Description: string;
  DefaultValue: boolean;
  Name: string;
  Type: string;
  InclusiveMin: number;
  InclusiveMax: number;
}

export interface IAddOns {
  Name: string;
  Type: string;
  Description: string;
  AssociatedFields?: ISubscriptionOption[];
}

export interface IMode {
  Id: string;
  DisplayName: string;
}

export interface IWarningConfig {
  ErrorId: string;
  ResolvedIn: string;
  Message: string;
  FieldNames: string[];
}

export interface ISubscriptionOptions {
  Modes: IMode[];
  CloudFeatures: string[];
  Fields: ISubscriptionOption[];
  Addons: IAddOns[];
  Packs: IPackSelection[];
  Warnings: IWarningConfig[];
}

export interface ISubscriptionSelections {
  Standard: ISubscriptionOptions;
  Professional: ISubscriptionOptions;
}

export interface ISubscriptionMetaData {
  LicenseName: string;
  CustomerName: string;
  Location: string;
  SalesForceLeadOrContactId: string;
  Created: string | null;
  Expires: string;
  Seats: number;
  TargetUsers: number;
  SiteLicense: boolean;
  PrimaryLicense: boolean;
  Trial: boolean;
  TrapezeTier: string;
  Perpetual: boolean;
  FirstUsed: string | null;
  SubscriptionAdminUsers: string[];
  SubscriptionAdminGroups: string | null;
}

export interface ISubscriptionValues {
  MetaData: ISubscriptionMetaData;
  ModesTrapeze: string[];
  ModesBrowser: string[];
  CloudFeatures: string[];
  FieldValues: { [key: string]: any };
  AddonValues: { [key: string]: any };
  AddonValuesSelectable: {
    Integrations: string[];
  };
  SubscriptionId: string;
  BoughtOnline: boolean;
  CustomerId?: number;
  Packs: string[];
}

export interface IUserComm {
  UserComm: IUserCommInfo;
  Frequency: string;
  Condition: IUserCommCondition;
  LastEditedByEmail?: string;
  CopyId: string;
  Released: boolean;
  ReviewerEmail?: string;
  PreviewUsersData?: IUserCommPreviewUsers[];
  Statistics?: IUserCommStats;
}

export interface IUserCommCondition {
  Version?: string;
  VersionConditionType?: string;
  StartUTC?: string;
  EndUTC?: string;
  Country?: string;
  LastEditedByEmail?: string;
}

export interface IUserCommColors {
  background?: number;
  text?: number;
  link?: number;
}

export interface IUserCommDialogSize {
  Width: number;
  Height: number;
}

export interface IUserCommInfo {
  Content: string;
  Mode: string;
  Title?: string;
  When: string;
  id?: string;
  MessageLevel?: string;
  DialogSize?: IUserCommDialogSize;
  ResponseOption?: any;
  BackColor?: number;
  TextColor?: number;
  LinkColor?: number;
  SupportsIE?: boolean;
}

export interface IUserCommPreviewUsers {
  Email: string;
  Id: string;
}

export interface IUserCommStats {
  UserReceivedUserComm: number;
  UsersDismissedUserComm: number;
}

export interface IVersionUsage {
  Email: string;
  Version: string;
  ActionDate: string;
  UserId: string;
  UserName: string;
}

export interface ILicenseServerService {
  addConsumerToPack(packData: IConsumerPackData): Promise<any>;
  addUserCommReviewer(email: string, userCommId: string): Promise<boolean>;
  addUserCommTestUser(email: string, userCommId: string): Promise<boolean>;
  bulkStampDelete(deleteStampIds: string[]): Promise<any>;
  bulkPresetDelete(deletePresetIds: string[]): Promise<any>;
  isCancel(error: any): boolean;
  createCustomerRequest(customerData: ICreateCustomer): Promise<any>;
  createLicenceRequest(customerData: ICreateLicence, customerID: number): Promise<any>;
  customerUpdateRequest(customerData: ICustomer): Promise<boolean>;
  deleteUserComm(commId: string): Promise<boolean>;
  getBlockedResources(): Promise<IBlockedRecources>;
  getCustomers(): Promise<ICustomer[]>;
  getCustomerUsers(customerName: string): Promise<ICustomerUser[]>;
  getDomainFederated(domain: string): Promise<boolean>;
  getGroups(): Promise<IGroup[]>;
  getLicenseAudit(licenseId: string): Promise<ILicenseAuditItem[]>;
  getLicenseCustomer(customerId: string, showExpired?: boolean): Promise<ILicenseCustomerDetails>;
  getLicenseCustomers(
    search?: string,
    filters?: ILicenseCustomerFilter,
    offset?: number,
    limit?: number
  ): Promise<ILicenseCustomersResponse>;
  getLicenseValues(licenseId: number): Promise<ISubscriptionValues>;
  getPacksBySubscriptionId(subscriptionId: string): Promise<IPack[]>;
  getPackConsumersBySubscriptionId(subscriptionId: string, packId: string): Promise<IResourceConsumers>;
  getPendingInvites(): Promise<IInvitation[]>;
  getPendingTrials(): Promise<ITrial[]>;
  getPresetsBySubscriptionId(subscriptionId: string): Promise<IPreset[]>;
  getSettingsByLicenseId(licenseId: number): Promise<ISettingSet[]>;
  getSettingList(subscriptionId: string): Promise<ISettingDefinition[]>;
  getSettingSets(): Promise<ISettingSet[]>;
  getSettingSetUsers(settingsId: string): Promise<IAssignedUser[]>;
  getStampsBySubscription(subscriptionId: string): Promise<IStampData>;
  getSubscriptions(): Promise<ISubscription[]>;
  getSubscriptionValues(): Promise<ISubscriptionSelections>;
  getConsumers(itemId: string): Promise<IResourceConsumers>;
  getConfigurationConsumers(itemId: string): Promise<IResourceConsumers>;
  getSubscriptionInvites(subscriptionId: string): Promise<IInvitation[]>;
  getSubscriptionEstimateRequest(countryCode: string, quantity: number): Promise<ISubscriptionEstimate>;
  getSubscriptionSettingSets(subscriptionId: string): Promise<ISettingSet[]>;
  getUserComms(getStats: boolean): Promise<IUserComm[]>;
  getUserDetails(): Promise<IUserData>;
  getUserPresets(): Promise<IPresetData>;
  getUserStamps(): Promise<IStampData>;
  getVersionCount(licenseId: number, from: string | null, to: string | null): Promise<{ [key in string]: number }>;
  getVersionUsage(licenseId: number, from: string | null, to: string | null): Promise<IVersionUsage[]>;
  groupAddGroup(groupId: string, addGroupId: string, role: string): Promise<boolean>;
  groupCreate(name: string, FederationGroupId: string): Promise<boolean>;
  groupCreateConfiguration(name: string, email: string, fedGroupId: string): Promise<boolean>;
  groupDelete(groupId: string): Promise<boolean>;
  groupRename(groupId: string, name: string, level: number, federationGroupId: string): Promise<boolean>;
  inviteAcceptReject(inviteId: string, accept: boolean): Promise<boolean>;
  inviteDelete(inviteId: string): Promise<boolean>;
  licenseAddSubscriptionAdmin(licenseId: number, email: string): Promise<boolean>;
  licenseConvertTrial(licenseId: number): Promise<boolean>;
  licenseCustomerUpdate(customerId: number, customerData: ILicenseCustomerDetails): Promise<boolean>;
  licenseSendEmail(licenseId: number, details: ILicenseEmailDetails): Promise<boolean>;
  licenseSetCloudSharingState(licenseId: number, enabled: boolean): Promise<boolean>;
  licenseSetIDPState(licenseId: number, enabled: boolean): Promise<boolean>;
  licenseUpdateExpiry(licenseId: number, expiryDate: string): Promise<boolean>;
  licenseUpdateSeats(licenseId: number, seatCount: number): Promise<boolean>;
  licenseUpdateTarget(licenseId: number, targetCount: number): Promise<boolean>;
  paymentSourceUpdateRequest(customerId: string): Promise<IPaymentSourceLink>;
  newCustomerPurchaseRequest(requestData: INewCustomerPurchaseRequest): Promise<ISubscriptionPurchaseLink>;
  accessRevoke(subscriptionId: string, consumerId: string): Promise<boolean>;
  releaseResources(resourceList: string[]): Promise<boolean>;
  releaseUserComm(commId: string): Promise<boolean>;
  removeConsumerFromPack(subscriptionId: string, packId: string, email: string, groupId: string): Promise<any>;
  removeUserCommTestUser(commId: string, userId: string): Promise<boolean>;
  requestDashboardData(request: IDataRequest): Promise<IDataResponse>;
  requestLicenseUsageData(request: IDataRequest): Promise<IDataResponse>;
  resourceAddGroup(
    resourceType: ResourceType,
    resourceId: string,
    resourceIds: string[] | null,
    groupId: string,
    role: string
  ): Promise<boolean>;
  resourceAddUser(
    resourceType: ResourceType,
    resourceId: string,
    resourceIds: string[] | null,
    email: string,
    role: string,
    emailList: string[] | null
  ): Promise<boolean>;
  resourceAddMultiUser(
    resourceType: ResourceType,
    resourceId: string[],
    emailList: string[],
    role: string
  ): Promise<boolean>;
  sendBlockedResources(type: string, resourceIds: string[]): Promise<boolean>;
  saveUserComm(userCommData: IUserComm): Promise<any>;
  setResourcesAdmin(resourceRequest: IAdminResourceRequest): Promise<any>;
  settingsMigrateUploadIni(
    subscriptionId: string,
    name: string,
    iniFile: File,
    overlayFile: File,
    stampFile: File,
    presetFile: File,
    processStamps: boolean,
    processPresets: boolean,
    processSettings: boolean,
    processOverlays: boolean
  ): Promise<ISettingsMigrationResponse>;
  settingsMigrateUploadZip(settingsId: string, zipFile: File): Promise<boolean>;
  settingsSetAssign(settingsId: string, groupId: string, email: string): Promise<boolean>;
  settingsSetCreate(name: string, subscriptionId: string, parentSetId: string): Promise<string>;
  settingsSetDelete(settingsId: string): Promise<boolean>;
  settingsSetUpdate(settingsSet: ISettingSet): Promise<boolean>;
  settingsSetUploadFile(settingsId: string, fileLink: string, file: File): Promise<boolean>;
  stampAssignUser(stampId: string, email: string, role: string): Promise<boolean>;
  stampAssignGroup(stampId: string, groupId: string, role: string): Promise<boolean>;
  stampMenuAssignUser(
    stampMenuId: string,
    shareOption: StampMenuShareOption,
    email: string,
    role: string
  ): Promise<boolean>;
  stampMenuAssignGroup(
    stampMenuId: string,
    shareOption: StampMenuShareOption,
    groupId: string,
    role: string
  ): Promise<boolean>;
  stampsMigrateUploadZip(settingsId: string, zipFile: File): Promise<string>;
  subscriptionCancelRequest(subscriptionId: string): Promise<boolean>;
  subscriptionChangeRequest(subscriptionId: string, quantity: number): Promise<boolean>;
  subscriptionInviteRequest(subscriptionId: string, email: string, role: string, flags: string[]): Promise<boolean>;
  updatePermissionFlags(resourceId: string, consumerId: string, flags: string[]): Promise<boolean>;
  userPermissionFlagsAdd(userId: string, flags: string[]): Promise<boolean>;
  addPermissionFlagsByEmail(userEmail: string, flags: string[], userCan: string[]): Promise<boolean>;
  userPermissionFlagRemove(userId: string, flag: string): Promise<boolean>;
  updateRole(subscriptionId: string, consumerId: string, role: string): Promise<boolean>;
  updateRolePermissionFlags(resourceId: string, consumerId: string, role: string, flags: string[]): Promise<boolean>;
  updateSubscriptionValues(subscriptionValues: ISubscriptionValues): Promise<boolean>;
  transferResource(
    resourceId: string | null,
    resourceIdList: string[] | null,
    email: string,
    type: string,
    copy: boolean
  ): Promise<boolean>;
  trialClaim(trialId: string): Promise<boolean>;
  trialApproveDecline(trialId: string, approve: boolean): Promise<boolean>;
  updatePreferences(prefs: IPrefs): Promise<boolean>;
}
