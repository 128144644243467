import React from 'react';
import { Grid } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import LoginLayout from './LoginLayout';
import LoginScreen from './LoginScreen';
import NewAccount from './NewAccount';
import ResetPassword from './ResetPassword';
import { useStyles } from './AuthUI.styles';

const AuthController: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction="column"
      className={classes.controllerHeight}
      alignContent="center"
      justifyContent="center"
    >
      <Grid container item justifyContent="center">
        <LoginLayout>
          <Switch>
            <Route path="/auth/signin" component={LoginScreen} />
            <Route path="/auth/new-account" component={NewAccount} />
            <Route path="/auth/reset" component={ResetPassword} />
          </Switch>
        </LoginLayout>
      </Grid>
    </Grid>
  );
};

export default AuthController;
