import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContent: {
    overflowY: 'auto',
    paddingX: theme.spacing(10),
    paddingTop: theme.spacing(8),
    '&.light': {
      background: theme.palette.objective.light.day,
      borderTop: '2px solid' + theme.palette.objective.light.day,
    },
    '&.dark': {
      background: theme.palette.objective.light.mid,
    },
  },
}));
