import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, FormHelperText, Grid, Link, styled, TextField, Typography } from '@material-ui/core';
import { Button, ButtonTypes } from 'odl-components';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { useStyles } from './AuthUI.styles';
import FieldLabel from './FieldLabel';
import Spacer from 'components/Spacer/Spacer';

interface Props {
  email: string;
  returnTo?: (destination: string) => void;
}

const VerificationScreen: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<string | null>(null);
  const [verified, setVerified] = useState<boolean>(false);
  const [alreadyVerified, setAlreadyVerified] = useState<boolean>(false);
  const [resent, setResent] = useState<boolean>();

  const onCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value.trim());
  };

  const onBackClick = () => {
    history.push('/auth/signin');
  };

  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(props.email, code);
      setVerified(true);
      if (props.returnTo) {
        props.returnTo('password');
      }
    } catch (error: any) {
      console.log('error signing in', error.__type);
      setCodeError(error.message);
      if (error.message.includes('status is CONFIRMED')) {
        setCodeError(t('Your email address has already been confirmed'));
        setAlreadyVerified(true);
      }
    }
  }

  async function onResendClick() {
    try {
      await Auth.resendSignUp(props.email);
      setResent(true);
    } catch (error: any) {
      console.log(error);
    }
  }

  const onBackToLoginClick = () => {
    if (props.returnTo) {
      props.returnTo('login');
    } else {
      history.push('/auth/signin');
    }
  };

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        history.push('/auth/signin');
      }, 2000);
    }
  }, [verified, history]);

  useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-code')?.focus();
  });

  return (
    <Box>
      {verified ? (
        <React.Fragment>
          <Grid container direction="column" justifyContent="center">
            <Spacer y={6} />
            <Grid container item justifyContent="center">
              <Icon className="icon icon-check-circle" />
            </Grid>
            <Spacer y={6} />
            <Grid item>
              <Typography className={classes.header} align="center">
                {t('Email address has been verified')}
              </Typography>
            </Grid>
            <Spacer y={6} />
            <Grid item>
              <Typography align="center">{t('Returning back to sign in page')}</Typography>
            </Grid>
          </Grid>
          <Spacer y={6} />
        </React.Fragment>
      ) : (
        <Grid container item direction="column" xs justifyContent="center">
          <Spacer y={4} />
          <Grid item>
            <Typography className={classes.header} align="center">
              {t('Verify new account')}
            </Typography>
          </Grid>
          <Spacer y={4} />
          <Grid item>
            <Typography align="center">
              {t(`Please enter below the verfication code sent to your {{email}} inbox`, { email: props.email })}
            </Typography>
          </Grid>
          <Spacer y={8} />
          <Grid container item justifyContent="flex-start">
            <FieldLabel required label={t('Verification Code')} />
          </Grid>
          <Spacer y={2} />
          <Grid item>
            <TextField
              id="textfield-code"
              fullWidth
              placeholder="Code"
              onChange={onCodeChange}
              variant="outlined"
              disabled={alreadyVerified}
              error={codeError !== null && !verified}
            />
          </Grid>
          {codeError && !verified && (
            <FormHelperText id="component-helper-text" error>
              <span className="icon icon-alert odlTextInput__icon" /> {codeError}
            </FormHelperText>
          )}
          <Spacer y={4} />
          <Grid item>
            {alreadyVerified ? (
              <Button
                a11yId="button-back-to-login"
                type={ButtonTypes.PRIMARY}
                text={t('Back to sign in')}
                fullWidth
                onClickHandler={onBackClick}
              />
            ) : (
              <Button
                a11yId="button-verify"
                type={ButtonTypes.PRIMARY}
                text={t('Verify')}
                fullWidth
                onClickHandler={confirmSignUp}
              />
            )}
          </Grid>
          <Spacer y={6} />
          <Grid container item direction="row">
            <Grid container item xs justifyContent="flex-start">
              <PointerLink onClick={onResendClick}>
                <Grid container item justifyContent="flex-start" alignContent="center" direction="row">
                  <Grid item>
                    <Typography>{t('Resend Code')}</Typography>
                  </Grid>
                  <Spacer x={1} />
                  <Grid item xs={1}>
                    {resent && <Box className="icon icon-check-circle-outline" fontSize={14} />}
                  </Grid>
                </Grid>
              </PointerLink>
            </Grid>
            <Grid container item xs justifyContent="flex-end">
              <PointerLink onClick={onBackToLoginClick}>
                <Typography>{t('Back to sign in')}</Typography>
              </PointerLink>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerificationScreen;

const PointerLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover .MuiTypography-root': {
    textDecoration: 'underline',
  },
});

const Icon = styled(Box)(({ theme }) => ({
  color: theme.palette.objective.green.main,
  fontSize: '30px',
}));
