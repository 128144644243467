import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, FormControl, FormHelperText, Grid, Link, styled, TextField, Typography } from '@material-ui/core';
import { Button, ButtonTypes } from 'odl-components';

import { useStyles } from './AuthUI.styles';
import Spacer from 'components/Spacer/Spacer';
import FieldLabel from './FieldLabel';

interface Props {
  onEmailSubmit: (email: string) => void;
}

const EmailSection: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const onSubmitClick = () => {
    if (emailValid) {
      props.onEmailSubmit(email);
    } else {
      setShowError(true);
    }
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (EmailValidator.validate(event.target.value)) {
      setEmailValid(true);
      setShowError(false);
    } else {
      setEmailValid(false);
    }
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter' && emailValid) props.onEmailSubmit(email);
  };

  const onNewAccountClick = () => {
    history.push('/auth/new-account');
  };

  useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-email')?.focus();
  }, []);

  return (
    <Grid container direction="column">
      <Spacer y={2} />
      <Grid container item xs justifyContent="center">
        <Typography className={classes.header} align="center">
          {t('Sign in')}
        </Typography>
      </Grid>
      <Spacer y={6} />
      <FormControl fullWidth>
        <Grid container item direction="column" justifyContent="flex-start">
          <Grid item>
            <FieldLabel label={t('Email address')} required />
          </Grid>
          <Spacer y={2} />
          <Grid item>
            <TextField
              id="textfield-email"
              fullWidth
              placeholder="Your work email address"
              onChange={onEmailChange}
              autoComplete="off"
              variant="outlined"
              InputProps={{
                startAdornment: <Box className={clsx('icon icon-email-outline', classes.iconSpace)} />,
              }}
              onKeyPress={onKeyPress}
              error={showError}
            />
          </Grid>
        </Grid>
        {showError && (
          <FormHelperText id="component-helper-text" error>
            <span className="icon icon-alert" /> {t('Email address is required')}
          </FormHelperText>
        )}
        <Spacer y={6} />
      </FormControl>
      <Grid item>
        <Button
          text={t('next')}
          type={ButtonTypes.PRIMARY}
          fullWidth
          onClickHandler={onSubmitClick}
          disabled={email.length === 0 ? false : showError || !emailValid}
        />
      </Grid>
      <Spacer y={6} />
      <Grid item>
        <PointerLink onClick={onNewAccountClick}>
          <Typography>{t('Create a new account')}</Typography>
        </PointerLink>
      </Grid>
    </Grid>
  );
};

export default EmailSection;

const PointerLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});
