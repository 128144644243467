import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Grid } from '@material-ui/core';
import { useStyles } from './AuthUI.styles';
import { Auth, Hub } from 'aws-amplify';
import { useStoreDispatch } from 'store/hooks';
import { serviceContainer } from 'services/serviceContainer';
import {
  setAuthenticated,
  setUserUniqueId,
  setUserFirstName,
  setUserLastName,
  setUserEmail,
  setUserPermissions,
  setUserPrefs,
  setEmailVerified,
  setFederatedLogin,
} from 'store/app-state/authentication/authentication';

import useWindowDimensions from 'hooks/useWindowDimensions';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import InformationPage from './InformationPage';
import AuthController from './AuthController';

const AuthSegments = ['signin', 'new-account', 'reset'];

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const { height } = useWindowDimensions();
  const screenWidthMatch = useScreenWidthMatch();

  const dispatch = useStoreDispatch();
  const history = useHistory();
  const location = useLocation();
  const [originalPage, setOriginalPage] = useState<string>('');

  useEffect(() => {
    let redirectPage: string = '';

    function checkSession() {
      Auth.currentSession()
        .then((sess) => {
          if (sess.isValid()) {
            const token = sess.getIdToken();
            const payload = token.decodePayload();
            dispatch(setUserUniqueId(payload.sub));
            dispatch(setUserFirstName(payload.given_name));
            dispatch(setUserLastName(payload.family_name));
            dispatch(setUserEmail(payload.email));
            dispatch(setEmailVerified(payload.email_verified));
            dispatch(setFederatedLogin(payload.identities && payload.identities.length > 0));
            dispatch(setAuthenticated(true));
            serviceContainer.cradle.licenseServer.getUserDetails().then((details) => {
              dispatch(setUserPermissions(details.UserCan));
              dispatch(setUserPrefs(details.ClientPrefs));
              history.push('/' + redirectPage);
            });
          } else {
            if (redirectPage !== '') {
              history.push('/auth/signin?path=' + redirectPage);
            } else {
              history.push('/auth/signin');
            }
          }
        })
        .catch(() => {
          if (redirectPage !== '') {
            history.push('/auth/signin?path=' + redirectPage);
          } else {
            history.push('/auth/signin');
          }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // the customOAuthState event happens after the signIn event and contains the page to redirect to
          // so use a timeout in case there isn't a custom state or it doesn't arrive
          setTimeout(() => checkSession(), 100);
          break;
        case 'cognitoHostedUI':
          // the cognitoHostedUI event occurs after the signIn event
          break;
        case 'customOAuthState':
          redirectPage = data;
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
    if (location.pathname !== '/auth/signin') {
      const page = location.pathname.split('/').pop();
      if (page && !AuthSegments.includes(page)) {
        redirectPage = page;
        setOriginalPage(page);
      }
      checkSession();
    }
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box height={height - 1}>
      <Grid container direction="column" alignContent="center">
        <Grid container direction="row">
          {screenWidthMatch.lg && (
            <Grid container item xs className={classes.infoGrid} alignContent="center" justifyContent="center">
              <InformationPage segment={originalPage} />
            </Grid>
          )}
          <Grid container item xs className={classes.loginGrid} alignContent="center" justifyContent="center">
            <AuthController />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
