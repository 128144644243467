import React from 'react';
import { Box, Grid } from '@material-ui/core';

import trapezeLogo from 'assets/images/logo-objective-trapeze-white-H.svg';
import Spacer from 'components/Spacer/Spacer';

type Props = {
  children?: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <Grid container item direction="column">
        <Spacer y={4} />
        {window.screen.width < 370 ? (
          <Grid container item xs justifyContent="center">
            <Box className="icon icon-objective-logo-trapeze" fontSize={35} color="white" />
          </Grid>
        ) : (
          <img src={trapezeLogo} alt="Objective Trapeze logo" height="35px" />
        )}
        <Spacer y={4} />
        <Grid container item justifyContent="center">
          {children}
        </Grid>

        <Spacer y={4} />
      </Grid>
    </React.Fragment>
  );
};

export default LoginLayout;
