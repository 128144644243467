import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { ToastContainer, Slide } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import AppHeader from 'components/AppHeader/AppHeader';
import AppSideNavigation from 'components/AppSideNavigation/AppSideNavigation';
import { useStyles } from 'components/MainLayout/MainLayout.styles';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';
import clsx from 'clsx';

type Props = {
  children?: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { userPrefs } = useStoreSelector(selectAuthenticationState);
  const lightTheme = userPrefs.PortalTheme === 'light';

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" minHeight="100vh" maxHeight="100vh">
      <Box flexGrow={0} flexShrink={0}>
        <AppHeader lightTheme={lightTheme} />
      </Box>
      <Box flexGrow={1} display="flex" overflow="hidden">
        <Box
          flexGrow={0}
          flexShrink={0}
          bgcolor={lightTheme ? theme.palette.objective.light.white : theme.palette.objective.dark.night}
        >
          <AppSideNavigation lightTheme={lightTheme} />
        </Box>
        <Box
          component="main"
          minWidth={320}
          flexGrow={1}
          className={clsx(classes.mainContent, lightTheme ? 'light' : 'dark')}
        >
          {children}
        </Box>
      </Box>
      <ToastContainer transition={Slide} autoClose={3000} />
    </Box>
  );
};

export default MainLayout;
