import axios, { AxiosHeaders, AxiosInstance, CancelTokenSource } from 'axios';
import { Auth } from 'aws-amplify';
import * as LicenceServerTypes from './LicenseServerService.types';
import { deepCopy } from 'deep-copy-ts';

/**
 * Convert preset type to a name, including dummy translation targets for the i18next parser
 * Used for getUserPresets and getPresetsBySubscriptionId
 */
const presetTypeToTypeName = (presetType: string): string => {
  let typeName = presetType;
  switch (presetType) {
    case 'Circle Measurement':
      // t('Circle Measurement')
      typeName = 'Circle Measurement';
      break;
    case 'Custom Arrows':
      // t('Arrow')
      typeName = 'Arrow';
      break;
    case 'Custom Circle Measurements':
      // t('Circle Measurement')
      typeName = 'Circle Measurement';
      break;
    case 'Custom Clouds':
      // t('Rectangle Cloud')
      typeName = 'Rectangle Cloud';
      break;
    case 'Custom Ellipses':
      // t('Ellipse')
      typeName = 'Ellipse';
      break;
    case 'Custom Filled Ellipses':
      // t('Filled Ellipse')
      typeName = 'Filled Ellipse';
      break;
    case 'Custom Filled Rects':
      // t('Filled Rectangle')
      typeName = 'Filled Rectangle';
      break;
    case 'Custom H Cone Of Visions':
      // t('Horizontal Cone of Vision')
      typeName = 'Horizontal Cone of Vision';
      break;
    case 'Custom Highlighters':
      // t('Highlighter')
      typeName = 'Highlighter';
      break;
    case 'Custom Hollow Ellipses':
      // t('Hollow Ellipse')
      typeName = 'Hollow Ellipse';
      break;
    case 'Custom Hollow Rects':
      // t('Hollow Rectangle');
      typeName = 'Hollow Rectangle';
      break;
    case 'Custom Lines':
      // t('Line')
      typeName = 'Line';
      break;
    case 'Custom Line Measurements':
      // t('Line Measurement')
      typeName = 'Line Measurement';
      break;
    case 'Custom Offset Lines':
      // t('Offset Line')
      typeName = 'Offset Line';
      break;
    case 'Custom Polygons':
      // t('Polygon')
      typeName = 'Polygon';
      break;
    case 'Custom Polygon Clouds':
      // t('Cloud')
      typeName = 'Cloud';
      break;
    case 'Custom Polylines':
      // t('Polyline')
      typeName = 'Polyline';
      break;
    case 'Custom Polyline Measurements':
      // t('Measurement')
      typeName = 'Measurement';
      break;
    case 'Custom Recession Planes':
      // t('Recession Plane')
      typeName = 'Recession Plane';
      break;
    case 'Custom Rects':
      // t('Rectangle')
      typeName = 'Rectangle';
      break;
    case 'Custom Scribbles':
      // t('Scribble')
      typeName = 'Scribble';
      break;
    case 'Custom Sticky Notes':
      // t('Sticky Note')
      typeName = 'Sticky Note';
      break;
    case 'Custom Straight Lines':
      // t('Straight Line')
      typeName = 'Straight Line';
      break;
    case 'Custom Texts':
      // t('Text Annotation')
      typeName = 'Text Annotation';
      break;
    case 'H Cone Of Vision':
      // t('Horizontal Cone of Vision')
      typeName = 'Horizontal Cone of Vision';
      break;
    case 'Line Measurement':
      // t('Line Measurement')
      typeName = 'Line Measurement';
      break;
    case 'Offset Line':
      // t('Offset Line')
      typeName = 'Offset Line';
      break;
    case 'Polyline Measurement':
      // t('Polyline Measurement')
      typeName = 'Polyline Measurement';
      break;
    case 'Recession Plane':
      // t('Recession Plane')
      typeName = 'Recession Plane';
      break;
    case 'Rectangle':
      // t('Rectangle')
      typeName = 'Rectangle';
      break;
    case 'Custom Points':
      // t('Point')
      typeName = 'Point';
      break;
    case 'Custom Callouts':
      // t('Callout')
      typeName = 'Callout';
      break;
  }
  return typeName;
};

/**
 * Convert stamp type to a StampMenuItemType enumeration
 */
const stampTypeToMenuItemType = (stampType: string): LicenceServerTypes.StampMenuItemType => {
  let typeName = LicenceServerTypes.StampMenuItemType.UNKNOWNSTAMP;
  switch (stampType.toLowerCase()) {
    case 'text':
      typeName = LicenceServerTypes.StampMenuItemType.TEXTSTAMP;
      break;
    case 'picture':
      typeName = LicenceServerTypes.StampMenuItemType.PICTURESTAMP;
      break;
    case 'multi':
      typeName = LicenceServerTypes.StampMenuItemType.MULTISTAMP;
      break;
  }
  return typeName;
};

/**
 * process the server stamp data into something more useful
 * Function used by getUserStamps & getStampsBySubscription
 */
const processStamps = (responseData: LicenceServerTypes.IStampServerData | any) => {
  let result = {
    Stamps: [] as LicenceServerTypes.IStamp[],
    Menus: [] as LicenceServerTypes.IStampMenu[],
    CustomerResources: responseData.CustomerResources,
  } as LicenceServerTypes.IStampData;
  // stamp menus are more tricky due to the nesting
  const ProcessChildren = (
    menu: LicenceServerTypes.IServerStampMenu,
    path: string
  ): LicenceServerTypes.IStampMenu[] => {
    let children = [] as LicenceServerTypes.IStampMenu[];
    menu.Children.forEach((child) => {
      let submenu = responseData.Menus.find((x: LicenceServerTypes.IServerStampMenu) => x.id === child);
      if (submenu) {
        children.push({
          id: submenu.id,
          Name: submenu.Name,
          Type: LicenceServerTypes.StampMenuItemType.MENU,
          MenuPath: path,
          Children: ProcessChildren(submenu, path + ' > ' + submenu.Name),
          MultipleUsers: submenu.MultipleUsers,
          Manager: submenu.CurrentUserRole === 3,
          UseOnly: submenu.CurrentUserRole === 1,
          IsShareable: submenu.IsShareable,
        } as LicenceServerTypes.IStampMenu);
      } else {
        let stamp = responseData.Stamps.find((x: LicenceServerTypes.IServerStamp) => x.id === child);
        if (stamp) {
          children.push({
            id: stamp.id,
            Name: stamp.Name,
            Type: stampTypeToMenuItemType(stamp.Type),
            MenuPath: path,
            Children: [] as LicenceServerTypes.IStampMenu[],
            MultipleUsers: stamp.MultipleUsers,
            Manager: stamp.CurrentUserRole === 3,
            UseOnly: stamp.CurrentUserRole === 1,
          } as LicenceServerTypes.IStampMenu);
        }
      }
    });
    return children;
  };
  let rootmenu = responseData.Menus.find((menu: LicenceServerTypes.IServerStampMenu) => menu.IsRootMenu === true);
  if (rootmenu) {
    rootmenu.Children.forEach((childId: string) => {
      let childmenu = responseData.Menus.find((menu: LicenceServerTypes.IServerStampMenu) => menu.id === childId);
      if (childmenu) {
        result.Menus.push({
          id: childmenu.id,
          Name: childmenu.Name,
          Type: LicenceServerTypes.StampMenuItemType.MENU,
          MenuPath: '',
          Children: ProcessChildren(childmenu, '> ' + childmenu.Name),
          MultipleUsers: childmenu.MultipleUsers,
          Manager: childmenu.CurrentUserRole === 3,
          UseOnly: childmenu.CurrentUserRole === 1,
          IsShareable: childmenu.IsShareable,
        } as LicenceServerTypes.IStampMenu);
      } else {
        // this is a root menu stamp
        let rootstamp = responseData.Stamps.find((stamp: LicenceServerTypes.IServerStamp) => stamp.id === childId);
        if (rootstamp) {
          result.Menus.push({
            id: rootstamp.id,
            Name: rootstamp.Name,
            Type: stampTypeToMenuItemType(rootstamp.Type),
            MenuPath: '',
            Children: [] as LicenceServerTypes.IStampMenu[],
            MultipleUsers: rootstamp.MultipleUsers,
            Manager: rootstamp.CurrentUserRole === 3,
            UseOnly: rootstamp.CurrentUserRole === 1,
          } as LicenceServerTypes.IStampMenu);
        }
      }
    });
  } else {
    // there is no root menu so iterate over the menu data to create the view information
    responseData.Menus.forEach((menu: LicenceServerTypes.IServerStampMenu) => {
      // top level menus have no parent
      if (
        responseData.Menus.findIndex(
          (x: LicenceServerTypes.IServerStampMenu) => x.Children.findIndex((y) => y === menu.id) >= 0
        ) < 0
      ) {
        result.Menus.push({
          id: menu.id,
          Name: menu.Name,
          Type: LicenceServerTypes.StampMenuItemType.MENU,
          MenuPath: '',
          Children: ProcessChildren(menu, '> ' + menu.Name),
          MultipleUsers: menu.MultipleUsers,
          Manager: menu.CurrentUserRole === 3,
          UseOnly: menu.CurrentUserRole === 1,
          IsShareable: menu.IsShareable,
        } as LicenceServerTypes.IStampMenu);
      }
    });
  }
  const FindMenuPath = (menus: LicenceServerTypes.IStampMenu[], stampId: string): string => {
    let path = '';
    for (let item of menus) {
      if (item.Type === LicenceServerTypes.StampMenuItemType.MENU && item.Children.length > 0) {
        path = FindMenuPath(item.Children, stampId);
        if (path.length > 0) break;
      }
      if (item.id === stampId) {
        path = item.MenuPath;
        break;
      }
    }
    return path;
  };
  responseData.Stamps.forEach((stamp: LicenceServerTypes.IServerStamp) => {
    if (!rootmenu) {
      // no root menu so top level stamps are those that are not part of any menu
      if (
        responseData.Menus.findIndex(
          (x: LicenceServerTypes.IServerStampMenu) => x.Children.findIndex((y) => y === stamp.id) >= 0
        ) < 0
      ) {
        result.Menus.push({
          id: stamp.id,
          Name: stamp.Name,
          Type: stampTypeToMenuItemType(stamp.Type),
          MenuPath: '',
          Children: [] as LicenceServerTypes.IStampMenu[],
          MultipleUsers: stamp.MultipleUsers,
          Manager: stamp.CurrentUserRole === 3,
          UseOnly: stamp.CurrentUserRole === 1,
        } as LicenceServerTypes.IStampMenu);
      }
    }
    // summarise this stamp for display
    result.Stamps.push({
      id: stamp.id,
      Name: stamp.Name,
      Type: stamp.Type,
      MenuPath: FindMenuPath(result.Menus, stamp.id),
      MultipleUsers: stamp.MultipleUsers,
      Manager: stamp.CurrentUserRole === 3,
      UseOnly: stamp.CurrentUserRole === 1,
    } as LicenceServerTypes.IStamp);
  });
  return result;
};

class LicenseServerService implements LicenceServerTypes.ILicenseServerService {
  private instance: AxiosInstance;
  private authInstance: AxiosInstance;
  private dataInstance: AxiosInstance;
  private dashboardInstance: AxiosInstance;
  private _estimateCancel?: CancelTokenSource;
  private _searchCancel?: CancelTokenSource;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_BASE_URL,
    });
    this.instance.interceptors.request.use(
      (config) => {
        return new Promise((resolve, reject) => {
          Auth.currentSession()
            .then((session) => {
              const authToken = 'Bearer ' + session.getIdToken().getJwtToken();
              if (config.headers) {
                config.headers.Authorization = authToken;
              } else {
                (config.headers as AxiosHeaders).set('Authorization', authToken);
              }
              resolve(config);
            })
            .catch((error) => {
              console.error('License Server Interceptor session error', error);
              reject(error);
            });
        });
      },
      (error) => {
        console.error('License Server Interceptor rejected', error);
      }
    );

    this.authInstance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_BASE_URL,
    });

    this.dataInstance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_DATA_URL,
    });
    this.dataInstance.interceptors.request.use(
      (config) => {
        return new Promise((resolve) => {
          const authToken = 'Basic TFNEIERhc2hib2FyZDowMDcgU2hvdyBNZSBUaGUgRGF0YSE=';
          if (config.headers) {
            config.headers.Authorization = authToken;
          } else {
            (config.headers as AxiosHeaders).set('Authorization', authToken);
          }
          resolve(config);
        });
      },
      (error) => {
        console.error('License Server data Interceptor rejected', error);
      }
    );

    this.dashboardInstance = axios.create({
      baseURL: process.env.REACT_APP_LICENCE_SERVER_API_DASHBOARD_DATA_URL,
    });
    this.dashboardInstance.interceptors.request.use(
      (config) => {
        return new Promise((resolve) => {
          const authToken = 'Basic TFNEIERhc2hib2FyZDowMDcgU2hvdyBNZSBUaGUgRGF0YSE=';
          if (config.headers) {
            config.headers.Authorization = authToken;
          } else {
            (config.headers as AxiosHeaders).set('Authorization', authToken);
          }
          resolve(config);
        });
      },
      (error) => {
        console.error('License Server dashboard data Interceptor rejected', error);
      }
    );
  }

  /**
   * Add consumers to pack
   * @param packData The pack and consumer data for the request
   * @returns An error message or no data if successful
   */
  public async addConsumerToPack(packData: LicenceServerTypes.IConsumerPackData): Promise<any> {
    const response = await this.instance.post<any>(`v1/pack/consumers`, packData);
    return response;
  }

  /**
   * Add user comm reviewer
   * @param email The email address of the reviewer to add
   * @param userCommId The user comm id for review
   * @returns An error message or no data if successful
   */
  public async addUserCommReviewer(email: string, userCommId: string): Promise<boolean> {
    const data = { Email: email, UserCommID: userCommId };
    const response = await this.instance.post<any>(`v1/usercomm/reviewer`, data);
    return response.status === 201;
  }

  /**
   * Add user comm test user
   * @param email The email address of the test user
   * @param userCommId The user comm id for user to test
   * @returns An error message or no data if successful
   */
  public async addUserCommTestUser(email: string, userCommId: string): Promise<boolean> {
    const data = { Email: email, UserCommID: userCommId };
    const response = await this.instance.post<any>(`v1/usercomm/testuser`, data);
    return response.status === 201;
  }

  /**
   * Request to bulk delete stamps
   * @param deleteStampIds Lists of stamps as a string array of stamp ids
   * @returns An error message or no data if successful
   */
  public async bulkStampDelete(deleteStampIds: string[]): Promise<any> {
    const response = await this.instance.post<any>('v1/stamps', { Stamps: [], Menus: [], Deletes: deleteStampIds });
    // return full response including response.status
    return response;
  }

  /**
   * Request to bulk delete presets
   * @param deletePresetIds Lists of preset as a string array of preset ids
   * @returns An error message or no data if successful
   */
  public async bulkPresetDelete(deletePresetIds: string[]): Promise<any> {
    const response = await this.instance.post<any>('v1/presets', { Presets: [], Deletes: deletePresetIds });
    // return full response including response.status
    return response;
  }

  /**
   * Check if the given error is a cancel from an Axios request
   * @param error The error to be checked
   * @returns TRUE if this is an Axios cancel, FALSE otherwise
   */
  public isCancel(error: any): boolean {
    return axios.isCancel(error);
  }

  /**
   * Create a customer with the given values
   * @param customerData The complete customer data
   * @returns An error message or no data if successful
   */
  public async createCustomerRequest(customerData: LicenceServerTypes.ICreateCustomer): Promise<string> {
    const response = await this.instance.post<any>('v1/LicenseCustomer', customerData);
    // the API endpoint will return a 201 response for create or update
    return response.status === 201 ? 'Successful' : 'response.data';
  }

  /**
   * Create a new licence for customer with given values
   * @param customerData The complete customer data
   * @returns An error message or no data if successful
   */
  public async createLicenceRequest(customerData: LicenceServerTypes.ICreateLicence, customerID: number): Promise<any> {
    const response = await this.instance.post<any>(`v1/LicenseCustomer/${customerID}/License`, customerData);
    // return full response to NewLicenceDialog component including response.status
    return response;
  }

  /**
   * Update the customer data with the given values
   * @param customerData The complete updated customer data
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async customerUpdateRequest(customerData: LicenceServerTypes.ICustomer): Promise<boolean> {
    const response = await this.instance.post<any>('v1/customers', customerData);
    // the API endpoint will return a 200 response for update
    return response.status === 200;
  }

  /**
   * Delete the given direct user communication
   * @param commId The user comms id
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async deleteUserComm(commId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/usercomm/${commId}`);
    return response.status === 204;
  }

  /**
   * Get a list of blocked resources for the current user
   * @returns A promise that resolves to an IBlockedRecources object
   */
  public async getBlockedResources(): Promise<LicenceServerTypes.IBlockedRecources> {
    const response = await this.instance.get<LicenceServerTypes.IBlockedRecources>('v1/userData/block');
    return response.data;
  }

  /**
   * Get the customer information for the current token
   * @returns A promise that resolves to an array of `ICustomer` entries
   */
  public async getCustomers(): Promise<LicenceServerTypes.ICustomer[]> {
    const response = await this.instance.get<LicenceServerTypes.ICustomer[]>('v1/customers');
    return response.data;
  }

  /**
   * Get the list of users for the customer with the given name
   * @param customerName The name of the customer to get the users for
   * @returns A promise resolving to an array of `ICustomerUser` entries
   */
  public async getCustomerUsers(customerName: string): Promise<LicenceServerTypes.ICustomerUser[]> {
    const config = { params: { trapezeCustomerName: customerName } };
    const response = await this.instance.get<LicenceServerTypes.ICustomerUser[]>('v1/users', config);
    return response.data;
  }

  /**
   * Check if the given domain is federated in the Objective AWS Cognito system
   * @param domain The domain to be checked
   * @returns A promise resolving to TRUE if federated, FALSE otherwise
   */
  public async getDomainFederated(domain: string): Promise<boolean> {
    const response = await this.authInstance.get<LicenceServerTypes.IFederatedResponse>(
      `v1/cognito/${domain}/federated`
    );
    return response.data.IsFederated;
  }

  /**
   * Get the group information for the current token
   * @returns A promise that resolves to an array of `IGroup` entries
   */
  public async getGroups(): Promise<LicenceServerTypes.IGroup[]> {
    const response = await this.instance.get<LicenceServerTypes.IGroup[]>('v1/groups');
    return response.data;
  }

  /**
   * Get the emailed license audit trail for a specific license id
   * @param licenseId The id of the current license
   * @returns A promise that resolves to an array of `ILicenseAuditItem` entries
   */
  public async getLicenseAudit(licenseId: string): Promise<LicenceServerTypes.ILicenseAuditItem[]> {
    const response = await this.instance.get<LicenceServerTypes.ILicenseAuditItem[]>(
      `v1/licenseFiles/${licenseId}/sent`
    );
    return response.data;
  }

  public async getLicenseCustomer(
    customerId: string,
    showExpired?: boolean
  ): Promise<LicenceServerTypes.ILicenseCustomerDetails> {
    const config = { params: { expired: showExpired ? true : undefined } };
    const response = await this.instance.get<LicenceServerTypes.ILicenseCustomerDetails>(
      `v1/LicenseCustomer/${customerId}`,
      config
    );
    return response.data;
  }

  /**
   * Get a list of License Customers filtered by the given country and search string
   * @param countryCode ISO 3166-1 alpha-2 country code to filter list by
   * @param search string to search for to limit the returned list
   * @returns A promise that resolves to an array of `ILicenseCustomer` entries
   */
  public async getLicenseCustomers(
    search?: string,
    filters?: LicenceServerTypes.ILicenseCustomerFilter,
    offset?: number,
    limit?: number
  ): Promise<LicenceServerTypes.ILicenseCustomersResponse> {
    if (this._searchCancel) {
      this._searchCancel.cancel();
    }
    this._searchCancel = axios.CancelToken.source();
    const config = {
      cancelToken: this._searchCancel.token,
      params: {
        searchString: search,
        country: filters?.countryCode,
        status: filters?.status,
        vetted: filters?.vetted,
        idp: filters?.idp,
        cloudFeature: filters?.cloudFeature,
        primaryOnly: filters?.primaryOnly,
        offset: offset,
        limit: limit,
      },
    };
    const response = await this.instance.get<LicenceServerTypes.ILicenseCustomersResponse>(
      'v1/licenseCustomers',
      config
    );
    return response.data;
  }

  /**
   * Gets list of current license values
   * @param licenseId The license id to be queried for license values
   * @returns A promise that resolves to an object of `ISubscriptionValues` entries
   */
  public async getLicenseValues(licenseId: number): Promise<LicenceServerTypes.ISubscriptionValues> {
    const response = await this.instance.get<LicenceServerTypes.ISubscriptionValues>(
      `v1/subscriptionvalues/${licenseId}`
    );
    return response.data;
  }

  /**
   * Get a list of configuration packs for specific Subscription Id
   * @returns A promise that resolves to an array of `IPack` entries containing the pack data
   */
  public async getPacksBySubscriptionId(subscriptionId: string): Promise<LicenceServerTypes.IPack[]> {
    const response = await this.instance.get<LicenceServerTypes.IPack[]>(`v1/subscription/${subscriptionId}/pack`);
    return response.data;
  }

  /**
   * Get a list consumers for a pack on a given subscription
   * @param subscriptionId id of given subscription
   * @param packId id of pack on the given subscription
   * @returns A promise that resolves to an object of `IResourceConsumers`
   */
  public async getPackConsumersBySubscriptionId(
    subscriptionId: string,
    packId: string
  ): Promise<LicenceServerTypes.IResourceConsumers> {
    const response = await this.instance.get<LicenceServerTypes.IResourceConsumers>(
      `v1/subscription/${subscriptionId}/pack/${packId}/consumers`
    );
    return response.data;
  }

  /**
   * Get a list of pending invitations for the current signed-in user
   * @returns A promise that resolves to an array of `IInvitation` entries
   */
  public async getPendingInvites(): Promise<LicenceServerTypes.IInvitation[]> {
    const response = await this.instance.get<LicenceServerTypes.IInvitation[]>('v1/invites/pending');
    return response.data;
  }

  /**
   * Get a list of pending trials if the current user has required permissions
   * @returns A promise that resolves to an array of `ITrial` entries
   */
  public async getPendingTrials(): Promise<LicenceServerTypes.ITrial[]> {
    const response = await this.instance.get<LicenceServerTypes.ITrial[]>('v1/trials');
    return response.data;
  }

  /**
   * Get a list of presets for specific Subscription Id
   * @returns A promise that resolves to an array of `IPreset` entries containing the preset data
   */
  public async getPresetsBySubscriptionId(subscriptionId: string): Promise<LicenceServerTypes.IPreset[]> {
    const response = await this.instance.get<LicenceServerTypes.IServerPreset[]>(
      `v1/subscription/${subscriptionId}/presets`
    );

    // process the server preset data into something more useful
    let result = [] as LicenceServerTypes.IPreset[];
    response.data.forEach((preset) => {
      result.push({
        id: preset.id,
        Type: presetTypeToTypeName(preset.PresetType),
        Name: preset.Name,
        Tag: preset.Tag,
        MultipleUsers: preset.MultipleUsers,
        Manager: true,
        UseOnly: preset.CurrentUserRole === 1,
        BlockedInTrapeze: preset.BlockedInTrapeze,
      });
    });
    return result;
  }

  /**
   * Retrieve list of Setting Collections by license ID
   * @param licenseId The license id
   * @returns A promise that resolves to an array of `ISettingSet` entries
   */
  public async getSettingsByLicenseId(licenseId: number): Promise<LicenceServerTypes.ISettingSet[]> {
    const response = await this.instance.get<LicenceServerTypes.ISettingSet[]>(`v1/settingsets?licenseId=${licenseId}`);
    return response.data;
  }

  /**
   * Get the settings definitions for the given subscription
   * @param subscriptionId The subscription id to be queried for setting definitions
   * @returns A promise that resolves to an array of `ISubscription` entries
   */
  public async getSettingList(subscriptionId: string): Promise<LicenceServerTypes.ISettingDefinition[]> {
    const response = await this.instance.get<LicenceServerTypes.ISettingDefinition[]>(
      `v1/settingdefinitions/subscription/${subscriptionId}`
    );
    return response.data;
  }

  /**
   * Get the settings sets information for the current token
   * @returns A promise that resolves to an array of `ISettingSet` entries
   */
  public async getSettingSets(): Promise<LicenceServerTypes.ISettingSet[]> {
    const response = await this.instance.get<LicenceServerTypes.ISettingSet[]>('v1/settingsets');
    return response.data;
  }

  /**
   * Get users assigned to a settings set
   * @param settingsId The settings set id to be queried for assigned users
   * @returns A promise that resolves to an array of `IAssignedUser` entries
   */
  public async getSettingSetUsers(settingsId: string): Promise<LicenceServerTypes.IAssignedUser[]> {
    const response = await this.instance.get<LicenceServerTypes.IAssignedUser[]>(`v1/settingset/${settingsId}/users`);
    return response.data;
  }

  /**
   * Get a list of stamps and associated menus from a specified subscription id
   * @param subscriptionId The subscription id to query
   * @returns A promise that resolves to an `IStampData` object containing the stamp data
   */
  public async getStampsBySubscription(subscriptionId: string): Promise<LicenceServerTypes.IStampData> {
    const response = await this.instance.get<LicenceServerTypes.IStampServerData>(
      `v1/subscription/${subscriptionId}/stamps`
    );

    return processStamps(response.data);
  }

  /**
   * Get the subscription information for the current token
   * @returns A promise that resolves to an array of `ISubscription` entries
   */
  public async getSubscriptions(): Promise<LicenceServerTypes.ISubscription[]> {
    const response = await this.instance.get<LicenceServerTypes.ISubscription[]>('v1/subscriptions');
    return response.data;
  }

  /**
   * Gets list of available settings for a license
   * @returns A promise that resolves an object of selectable options
   */
  public async getSubscriptionValues(): Promise<LicenceServerTypes.ISubscriptionSelections> {
    const response = await this.instance.get<LicenceServerTypes.ISubscriptionSelections>('v1/subscriptionvalues');
    return response.data;
  }

  /**
   * Get a list of consumers (users and groups) for the given item
   * @param itemId The item id to be queried for users
   * @returns A promise that resolves to an array of `IUserProfile` entries
   */
  public async getConfigurationConsumers(itemId: string): Promise<LicenceServerTypes.IResourceConsumers> {
    const response = await this.instance.get<LicenceServerTypes.IResourceConsumers>(
      `v1/subscription/consumers?resourceId=${itemId}`
    );
    return response.data;
  }

  /**
   * Get a list of consumers (users and groups) for the given item
   * @param itemId The item id to be queried for users
   * @returns A promise that resolves to an array of `IUserProfile` entries
   */
  public async getConsumers(itemId: string): Promise<LicenceServerTypes.IResourceConsumers> {
    const response = await this.instance.get<LicenceServerTypes.IResourceConsumers>(
      `v1/consumers?resourceId=${itemId}`
    );
    return response.data;
  }

  /**
   * Get a list of invitations for the given subscription
   * @param subscriptionId The subscription id to be queried for invitations
   * @returns A promise that resolves to an array of `IInvitation` entries
   */
  public async getSubscriptionInvites(subscriptionId: string): Promise<LicenceServerTypes.IInvitation[]> {
    const response = await this.instance.get<LicenceServerTypes.IInvitation[]>(
      `v1/invite?resourceId=${subscriptionId}`
    );
    return response.data;
  }

  /**
   * Get a cost estimate for the given country and quantity
   * @param countryCode ISO 3166-1 alpha-2 country code
   * @param quantity Number of users/seats for this subscription
   * @returns A promise that resolves to an `ISubscriptionEstimate` containing the cost estimate
   */
  public async getSubscriptionEstimateRequest(
    countryCode: string,
    quantity: number
  ): Promise<LicenceServerTypes.ISubscriptionEstimate> {
    if (this._estimateCancel) {
      this._estimateCancel.cancel();
    }
    this._estimateCancel = axios.CancelToken.source();
    const config = { cancelToken: this._estimateCancel.token };
    const body: LicenceServerTypes.ISubscriptionEstimateRequest = {
      PlanId: `trapeze-professional-${countryCode.toLowerCase()}`,
      PlanQuantity: quantity,
      BillingAddressCountry: countryCode.toUpperCase(),
    };
    const response = await this.instance.post<LicenceServerTypes.ISubscriptionEstimate>(
      'v1/subscriptionEstimateRequest',
      body,
      config
    );
    return response.data;
  }

  /**
   * Retrieve list of Setting Collections for a subscription ID
   * @param subscriptionId The subscription id
   * @returns A promise that resolves to an array of `ISettingSet` entries
   */
  public async getSubscriptionSettingSets(subscriptionId: string): Promise<LicenceServerTypes.ISettingSet[]> {
    const response = await this.instance.get<LicenceServerTypes.ISettingSet[]>(
      `v1/settingsets?subscriptionId=${subscriptionId}`
    );
    return response.data;
  }

  /**
   * Retrieve a list of all direct user communications
   * @returns A promise resolving to a list of `IUsercomms` containing the DUC details
   * Query param to always call for stats
   */
  public async getUserComms(getStats: boolean): Promise<LicenceServerTypes.IUserComm[]> {
    const response = await this.instance.get<any>(`v1/usercomms?statistics=${getStats}`);
    return response.data;
  }

  /**
   * Inform the License Server that the user details from the IDP should be updated and get the user details
   * @returns A promise resolving to an `IUserData` containing the user details
   */
  public async getUserDetails(): Promise<LicenceServerTypes.IUserData> {
    const response = await this.instance.get<any>('v1/userData');
    return response.data;
  }

  /**
   * Get a list of presets that the current user has access to
   * @returns A promise that resolves to an object containing the preset data
   */
  public async getUserPresets(): Promise<LicenceServerTypes.IPresetData> {
    const response = await this.instance.get<LicenceServerTypes.IPresetServerData>('v1/presets');
    // process the server preset data into something more useful
    let presetList = [] as LicenceServerTypes.IPreset[];
    response.data.Presets.forEach((preset) => {
      presetList.push({
        id: preset.id,
        Type: presetTypeToTypeName(preset.PresetType),
        Name: preset.Name,
        Tag: preset.Tag,
        MultipleUsers: preset.MultipleUsers,
        Manager: preset.CurrentUserRole === 3,
        UseOnly: preset.CurrentUserRole === 1,
        BlockedInTrapeze: preset.BlockedInTrapeze,
      });
    });
    return {
      Presets: presetList,
      Version: response.data.Version,
      CustomerResources: response.data.CustomerResources,
    };
  }

  /**
   * Get a list of stamps and associated menus that the current user has access to
   * @returns A promise that resolves to an `IStampData` object containing the stamp data
   */
  public async getUserStamps(): Promise<LicenceServerTypes.IStampData> {
    const response = await this.instance.get<LicenceServerTypes.IStampServerData>('v2/stamps');

    return processStamps(response.data);
  }

  /**
   * Get a list versions and count used for a specific license in the given date range
   * @param licenseId The id of the license to retrieve versions from
   * @param from The start date of the count query
   * @param to The end date of the count query
   * @returns A promise that resolves to an object containing the version data
   */
  public async getVersionCount(
    licenseId: number,
    from: string | null,
    to: string | null
  ): Promise<{ [key in string]: number }> {
    const response = await this.instance.get<{ [key in string]: number }>(
      `v1//license/${licenseId}/usagecount?from=${from}&to=${to}`
    );
    return response.data;
  }

  /**
   * Get a detailed list versions used for a specific license in the given date range
   * @param licenseId The id of the license to retrieve versions from
   * @param from The start date of the version query
   * @param to The end date of the version query
   * @returns A promise that resolves to an `IVersionUsage` list containing the version data
   */
  public async getVersionUsage(
    licenseId: number,
    from: string | null,
    to: string | null
  ): Promise<LicenceServerTypes.IVersionUsage[]> {
    const response = await this.instance.get<LicenceServerTypes.IVersionUsage[]>(
      `v1//license/${licenseId}/usage?from=${from}&to=${to}`
    );
    return response.data;
  }

  /**
   * Add a group to another group with the given role
   * @param groupId The group id to add the group to
   * @param addGroupId The group id to be added
   * @param role The role of the added group
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async groupAddGroup(groupId: string, addGroupId: string, role: string): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerRequest = {
      ResourceType: 'Group',
      ResourceId: groupId,
      ResourceIds: null,
      Email: null,
      Emails: null,
      Group: addGroupId,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Create a new group with the given name
   * @param name The name of the group
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async groupCreate(name: string, groupId: string): Promise<boolean> {
    const body: LicenceServerTypes.IGroupCreate = {
      Name: name,
      Level: 1,
      FederationGroupId: groupId,
    };
    const response = await this.instance.post<any>(`v1/groups`, body);
    return response.status === 204;
  }

  /**
   * Create a new group with the given name and group admin email
   * @param name The name of the group
   * @param email The email of the Group Admin
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async groupCreateConfiguration(name: string, email: string, fedGroupId: string): Promise<boolean> {
    const body = {
      Name: name,
      Level: 1,
      ManageEmail: email,
      FederationGroupId: fedGroupId,
    };
    const response = await this.instance.post<any>(`v1/groups`, body);
    return response.status === 204;
  }

  /**
   * Delete the given group
   * @param groupId The group id
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async groupDelete(groupId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/groups/${groupId}`);
    return response.status === 204;
  }

  /**
   * Rename a group to the given name
   * @param name The name of the group
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async groupRename(groupId: string, name: string, level: number, federationGroupId: string): Promise<boolean> {
    const body: LicenceServerTypes.IGroupUpdate = {
      Id: groupId,
      Name: name,
      Level: level,
      FederationGroupId: federationGroupId,
    };
    const response = await this.instance.post<any>(`v1/groups`, body);
    return response.status === 204;
  }

  /**
   * Accept or reject an invitation based on the invitee's decision
   * @param inviteId The invitation id
   * @param accept TRUE if the invitation is accepted, FALSE otherwise
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async inviteAcceptReject(inviteId: string, accept: boolean): Promise<boolean> {
    const body: LicenceServerTypes.IInviteAccept = {
      Accept: accept,
    };
    const response = await this.instance.post<any>(`v1/invites/pending/${inviteId}`, body);
    return response.status === 204;
  }

  /**
   * Delete the given invitation
   * @param inviteId The invitation id
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async inviteDelete(inviteId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/invite/${inviteId}`);
    return response.status === 204;
  }

  /**
   * Add a subscription administrator to the given license
   * @param licenseId The license id
   * @param email The email address of the admin to be added
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseAddSubscriptionAdmin(licenseId: number, email: string): Promise<boolean> {
    const body: LicenceServerTypes.IAddLicenseSubscriptionAdmin = {
      LicenseId: licenseId,
      AdminEmail: email,
    };
    const response = await this.instance.post<any>(`v1/SubscriptionAdmin`, body);
    return response.status === 200;
  }

  /**
   * Convert the given trial license to a subscription
   * @param licenseId The license id
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseConvertTrial(licenseId: number): Promise<boolean> {
    const body = {
      value: 'false',
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/Trial`, body);
    return response.status === 204;
  }

  /**
   * Update a customer with the given data
   * @param customerId The customer id
   * @param customerData The new details to update the customer with
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseCustomerUpdate(
    customerId: number,
    customerData: LicenceServerTypes.ILicenseCustomerDetails
  ): Promise<boolean> {
    const response = await this.instance.post<any>(`v1/licensecustomer/${customerId}`, customerData);
    return response.status === 204;
  }

  /**
   * Send the license file to the customer with the given details
   * @param licenseId The license id
   * @param details The email details for the customer
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseSendEmail(licenseId: number, details: LicenceServerTypes.ILicenseEmailDetails): Promise<boolean> {
    const response = await this.instance.post<any>(`v1/licenseFiles/${licenseId}/send`, details);
    return response.status === 204;
  }

  /**
   * Set the license Cloud Sharing feature flag state
   * @param licenseId The license id
   * @param enabled True to enable IDP for this license
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseSetCloudSharingState(licenseId: number, enabled: boolean): Promise<boolean> {
    const body = {
      value: enabled,
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/CloudFeatureActivate`, body);
    return response.status === 204;
  }

  /**
   * Set the license IDP flag state
   * @param licenseId The license id
   * @param enabled True to enable IDP for this license
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseSetIDPState(licenseId: number, enabled: boolean): Promise<boolean> {
    const body = {
      value: enabled,
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/IDP`, body);
    return response.status === 204;
  }

  /**
   * Update the given license expiry date
   * @param licenseId The license id
   * @param expiryDate The new expiry date for the license
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseUpdateExpiry(licenseId: number, expiryDate: string): Promise<boolean> {
    const body = {
      value: expiryDate,
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/EndsWhen`, body);
    return response.status === 204;
  }

  /**
   * Update the given license seat/user count
   * @param licenseId The license id
   * @param seatCount The maximum number of seats/users for the license
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseUpdateSeats(licenseId: number, seatCount: number): Promise<boolean> {
    const body = {
      value: seatCount,
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/MaxUsers`, body);
    return response.status === 204;
  }

  /**
   * Update the given license target seat/user count
   * @param licenseId The license id
   * @param targetCount The target count of seats/users for the license
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async licenseUpdateTarget(licenseId: number, targetCount: number): Promise<boolean> {
    const body = {
      value: targetCount,
    };
    const response = await this.instance.post<any>(`v1/license/${licenseId}/TargetUserCount`, body);
    return response.status === 204;
  }

  /**
   * Request a URL to update the payment source for the given customer
   * @param customerId The customer id
   * @returns A promise that resolves to an `IPaymentSourceLink` object containing the URL
   */
  public async paymentSourceUpdateRequest(customerId: string): Promise<LicenceServerTypes.IPaymentSourceLink> {
    const body: LicenceServerTypes.IPaymentSourceUpdateRequest = {
      CustomerId: customerId,
    };
    const response = await this.instance.post<LicenceServerTypes.IPaymentSourceLink>(
      'v1/paymentSourceUpdateRequest',
      body
    );
    return response.data;
  }

  /**
   * Make a subscription purchase request for a new customer
   * @param requestData An `INewCustomerPurchaseRequest` object containing purchase details
   * @returns A promise that resolves to an `ISubscriptionPurchaseLink` object containing the result
   */
  public async newCustomerPurchaseRequest(
    requestData: LicenceServerTypes.INewCustomerPurchaseRequest
  ): Promise<LicenceServerTypes.ISubscriptionPurchaseLink> {
    const response = await this.instance.post<LicenceServerTypes.ISubscriptionPurchaseLink>(
      'v1/newCustomerPurchaseRequest',
      requestData
    );
    return response.data;
  }

  /**
   * Revoke access to an access controlled item for the given user
   * @param itemId The item id to revoke access from
   * @param consumerId The user that is having access revoked
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async accessRevoke(itemId: string, consumerId: string): Promise<boolean> {
    const config = { params: { resourceId: itemId } };
    const response = await this.instance.delete<any>(`v1/consumers/${consumerId}`, config);
    return response.status === 204;
  }

  /**
   * Release customer resources back to customer
   * @param resourceList List if resource Ids to release
   * @param all TRUE if releasing all resources or FALSE if releasing a subset of customer resources
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async releaseResources(resourceIdList: string[]): Promise<boolean> {
    const response = await this.instance.delete<any>('v1/AdminResources', {
      data: { ResourceIdsToRemove: resourceIdList },
    });
    // the API endpoint will return a 201 response for create or update
    return response.status === 201;
  }

  /**
   * Release a reviewed User Comunication
   * @param commId Id of the user comms to release
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async releaseUserComm(commsId: string): Promise<boolean> {
    const response = await this.instance.post<any>('v1/usercomm/release', {
      UserCommId: commsId,
    });
    return response.status === 201;
  }

  /**
   * Removes designated consumers from pack
   * @param packData The pack and consumer data for the removal request
   * @returns TRUE if successful, FALSE otherwise
   */
  public async removeConsumerFromPack(
    subscriptionId: string,
    packId: string,
    email: string,
    groupId: string
  ): Promise<boolean> {
    const config = {
      data: JSON.stringify({
        SubscriptionId: subscriptionId,
        PackId: packId,
        Email: email,
        GroupId: groupId,
      }),
    };
    const response = await this.instance.delete<any>(`v1/pack/consumers`, config);
    return response.status === 204;
  }

  /**
   * Removes test user from user comm
   * @param commId The id of the specified user communication
   * @param userId The id of the user to be removed
   * @returns TRUE if successful, FALSE otherwise
   */
  public async removeUserCommTestUser(commId: string, userId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/usercomm/${commId}/testuser/${userId}`);
    return response.status === 204;
  }

  /**
   * Requests data for the dashboard graphs
   * @param request The information about what data is being requested
   * @returns The requested data
   */
  public async requestDashboardData(
    request: LicenceServerTypes.IDataRequest
  ): Promise<LicenceServerTypes.IDataResponse> {
    const response = await this.dashboardInstance.post<any>('v1/datarequest', request);
    return response.data;
  }

  /**
   * Requests data for the license usage graphs
   * @param request The information about what data is being requested
   * @returns The requested data
   */
  public async requestLicenseUsageData(
    request: LicenceServerTypes.IDataRequest
  ): Promise<LicenceServerTypes.IDataResponse> {
    const response = await this.dataInstance.post<any>('v1/datarequest', request);
    return response.data;
  }

  /**
   * Add a user to a resource with the given role
   * @param resourceType The type of the resource to add the user to
   * @param resourceId The resource id to assign the user to
   * @param email The email address of the user to be added
   * @param role The role of the user in this resource
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async resourceAddGroup(
    resourceType: LicenceServerTypes.ResourceType,
    resourceId: string,
    resourceIds: string[] | null,
    groupId: string,
    role: string
  ): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerRequest = {
      ResourceType: resourceType,
      ResourceId: resourceId,
      ResourceIds: resourceIds,
      Email: null,
      Emails: null,
      Group: groupId,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Add a list users to a list of resources with the given role
   * @param resourceType The type of the resource to add the user to
   * @param resourceIds The list of resource ids to assign to
   * @param emailList The list of email addresses to be added
   * @param role The role of the users in this resource
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async resourceAddMultiUser(
    resourceType: LicenceServerTypes.ResourceType,
    resourceIds: string[],
    emailList: string[],
    role: string
  ): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerMultiRequest = {
      ResourceType: resourceType,
      ResourceIds: resourceIds,
      Emails: emailList,
      Group: null,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Add a user to a resource with the given role
   * @param resourceType The type of the resource to add the user to
   * @param resourceId The resource id to assign the user to
   * @param email The email address of the user to be added
   * @param role The role of the user in this resource
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async resourceAddUser(
    resourceType: LicenceServerTypes.ResourceType,
    resourceId: string,
    resourceIds: string[] | null,
    email: string,
    role: string,
    emailList: string[]
  ): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerRequest = {
      ResourceType: resourceType,
      ResourceId: resourceId,
      ResourceIds: resourceIds,
      Email: email,
      Emails: emailList,
      Group: null,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Save a direct user comms with given values
   * @param customerData The complete user communication data
   * @returns An error message or no data if successful
   */
  public async saveUserComm(userCommData: LicenceServerTypes.IUserComm): Promise<any> {
    const response = await this.instance.post<any>(`v1/usercomm`, userCommData);
    // return full response to UserCommsCreation component including response.status
    return response;
  }

  /**
   * Request to block a list of resources displaying in Trapeze
   * @param type The resource type to be blocked
   * @param resourceIds The list of resource ids to be blocked
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async sendBlockedResources(type: string, resourceIds: string[]): Promise<boolean> {
    const body = { Type: type, Ids: resourceIds };
    const response = await this.instance.put<any>(`v1//userData/block`, body);
    return response.status === 200 || response.status === 204;
  }

  /**
   * Request access to customer resource
   * @param resourceRequest Lists of resources as an `IAdminResourceRequest` object
   * @returns An error message or no data if successful
   */
  public async setResourcesAdmin(resourceRequest: LicenceServerTypes.IAdminResourceRequest): Promise<any> {
    const response = await this.instance.post<any>('v1/AdminResources', resourceRequest);
    // return full response including response.status
    return response;
  }

  /***
   * Upload a file for a settings set file link
   * @param subscriptionId The settings set id
   * @param name The filepath that this file is linked to
   * @param iniFile The ini file to upload
   * @param overlayFile The overlay ini file to upload
   * @param overlayZipFile The overlay zip file to upload
   * @param stampFile The stamp ini file to upload
   * @param stampImageFile The stamp image ini file to upload
   * @param presetFile The preset ini file to upload
   * @param processStamps True to process stamps for this ini file
   * @param processPresets True to process presets for this ini file
   * @param processSettings True to process settings for this ini file
   * @param processOverlays True to process overlays for this ini file
   * @returns A promise that resolves to an `ISettingsMigrationResponse` object containing the result
   */
  public async settingsMigrateUploadIni(
    subscriptionId: string,
    name: string,
    iniFile: File,
    overlayFile: File,
    stampFile: File,
    presetFile: File,
    processStamps: boolean,
    processPresets: boolean,
    processSettings: boolean,
    processOverlays: boolean
  ): Promise<LicenceServerTypes.ISettingsMigrationResponse> {
    var body: LicenceServerTypes.ISettingsMigrationRequest = {
      SettingsSetId: null,
      SubscriptionId: subscriptionId,
      Name: name,
      ParentSettingSetId: '',
      IniFileContents: '',
      IniOverlaysContents: '',
      IniPresetContents: '',
      IniPrintersContents: '',
      IniPrinterGroupsContents: '',
      IniStampContents: '',
      ProcessSettingSetData: processSettings,
      ProcessStamps: processStamps,
      ProcessOverlays: processOverlays,
      ProcessPresets: processPresets,
      IniExemptionCodeGroupContents: [] as string[],
    };
    const promises = [] as Promise<string | void>[];
    if (iniFile) {
      promises.push(
        iniFile.text().then((result) => {
          body.IniFileContents = result;
        })
      );
    }
    if (overlayFile) {
      promises.push(
        overlayFile.text().then((result) => {
          body.IniOverlaysContents = result;
        })
      );
    }
    if (stampFile) {
      promises.push(
        stampFile.text().then((result) => {
          body.IniStampContents = result;
        })
      );
    }
    if (presetFile) {
      promises.push(
        presetFile.text().then((result) => {
          body.IniPresetContents = result;
        })
      );
    }
    await Promise.all(promises).catch((error) => {
      console.error('Unable to read all ini files', error);
      return false;
    });

    const response = await this.instance.post<any>('v1/settingset/migration', body);
    return response.data;
  }

  /**
   * Upload a zip of files (typically images) for a migrated ini file
   * @param settingsId The settings set id
   * @param zipFile The zip file to upload
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async settingsMigrateUploadZip(settingsId: string, zipFile: File): Promise<boolean> {
    const response = await this.instance.post<any>(`v1/settingset/migration/${settingsId}/file`, zipFile, {
      headers: { 'Content-Type': zipFile.type },
    });
    return response.status === 204;
  }

  /**
   * Assign a user or group to a settings set
   * @param settingsId The settings set id
   * @param groupId The group id to assign
   * @param email The email address of the user to be assigned
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async settingsSetAssign(settingsId: string, groupId: string, email: string): Promise<boolean> {
    const body: LicenceServerTypes.IAssignSettingsRequest = {
      SettingSetId: settingsId,
      Email: email,
      GroupId: groupId,
    };
    const response = await this.instance.post<any>('v1/settingset/assign', body);
    return response.status === 204;
  }

  /**
   * Create a new settings set with the given name
   * @param name The name of the settings set
   * @returns A promise resolving to a string containing the settings set id
   */
  public async settingsSetCreate(name: string, subscriptionId: string, parentSetId: string): Promise<string> {
    const body: LicenceServerTypes.ISettingSetInfo = {
      id: '',
      Name: name,
      SubscriptionId: subscriptionId,
      ParentSettingSetId: parentSetId,
      Settings: {},
      AdvancedSettings: '',
      Files: [] as string[],
    };
    const response = await this.instance.post<any>('v1/settingset', body);
    if (response.status === 200) {
      return response.data ? response.data.id : '';
    }
    return '';
  }

  /**
   * Delete the given settings set
   * @param settingsId The settings set id
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async settingsSetDelete(settingsId: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/settingset/${settingsId}`);
    return response.status === 204;
  }

  /**
   * Update a settings set to the given value
   * @param settingsSet The updated settings set
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async settingsSetUpdate(settingsSet: LicenceServerTypes.ISettingSet): Promise<boolean> {
    var body: LicenceServerTypes.ISettingSetInfo = {
      id: settingsSet.id,
      SubscriptionId: settingsSet.SubscriptionId,
      ParentSettingSetId: settingsSet.ParentSettingSetId,
      Settings: deepCopy(settingsSet.Settings),
      Name: settingsSet.Name,
      AdvancedSettings: settingsSet.AdvancedSettings,
      Files: Object.keys(settingsSet.Files),
    };

    const response = await this.instance.post<any>('v1/settingset', body);
    return response.status === 200;
  }

  /**
   * Upload a file for a settings set file link
   * @param settingsId The settings set id
   * @param fileLink The filepath that this file is linked to
   * @param file The contents of the file to upload
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async settingsSetUploadFile(settingsId: string, fileLink: string, file: File): Promise<boolean> {
    const response = await this.instance.post<any>(`v1/settingset/${settingsId}/file?filepath=${fileLink}`, file, {
      headers: { 'Content-Type': file.type },
    });
    return response.status === 204;
  }

  /**
   * Assign a user to a stamp with the given role
   * @param stampId The stamp id to assign the user to
   * @param email The email address of the user to be assigned
   * @param role The role of the invited user for this stamp
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async stampAssignUser(stampId: string, email: string, role: string): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerRequest = {
      ResourceType: 'Stamp',
      ResourceId: stampId,
      ResourceIds: null,
      Email: email,
      Emails: null,
      Group: null,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Assign a group to a stamp with the given role
   * @param stampId The stamp id to assign the user to
   * @param groupId The id of the group to be assigned
   * @param role The role of the invited user for this stamp
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async stampAssignGroup(stampId: string, groupId: string, role: string): Promise<boolean> {
    const body: LicenceServerTypes.IAddCustomerRequest = {
      ResourceType: 'Stamp',
      ResourceId: stampId,
      ResourceIds: null,
      Email: null,
      Emails: null,
      Group: groupId,
      RoleForUser: role,
    };
    const response = await this.instance.post<any>('v1/addConsumer', body);
    return response.status === 204;
  }

  /**
   * Assign a user to a stamp menu and the associated stamps with the given role
   * @param stampMenuId The stamp menu id to assign the user to
   * @param shareOption How this stamp menu should be shared
   * @param email The email address of the user to be assigned
   * @param role The role of the invited user for this stamp
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async stampMenuAssignUser(
    stampMenuId: string,
    shareOption: LicenceServerTypes.StampMenuShareOption,
    email: string,
    role: string
  ): Promise<boolean> {
    const body: LicenceServerTypes.IAddToStampMenuRequest = {
      MenuSharingOptions: shareOption,
      StampMenuId: stampMenuId,
      Email: email,
      Group: null,
      Role: role,
    };
    const response = await this.instance.post<any>('v1/addConsumerStampMenu', body);
    return response.status === 204;
  }

  /**
   * Assign a group to a stamp menu and the associated stamps with the given role
   * @param stampMenuId The stamp menu id to assign the user to
   * @param shareOption How this stamp menu should be shared
   * @param groupId The id of the group to be assigned
   * @param role The role of the invited user for this stamp
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async stampMenuAssignGroup(
    stampMenuId: string,
    shareOption: LicenceServerTypes.StampMenuShareOption,
    groupId: string,
    role: string
  ): Promise<boolean> {
    const body: LicenceServerTypes.IAddToStampMenuRequest = {
      MenuSharingOptions: shareOption,
      StampMenuId: stampMenuId,
      Email: null,
      Group: groupId,
      Role: role,
    };
    const response = await this.instance.post<any>('v1/addConsumerStampMenu', body);
    return response.status === 204;
  }

  /**
   * Upload a zip of stamps (typically images) for a migrated ini file
   * @param settingsId The settings set id
   * @param zipFile The zip file to upload
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async stampsMigrateUploadZip(settingsId: string, zipFile: File): Promise<string> {
    const response = await this.instance.post<any>(`v1/settingset/migration/stamps/${settingsId}/file`, zipFile, {
      headers: { 'Content-Type': zipFile.type },
    });
    return response.data;
  }

  /**
   * Cancel the given subscription
   * @param subscriptionId The subscription id to be cancelled
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async subscriptionCancelRequest(subscriptionId: string): Promise<boolean> {
    const body: LicenceServerTypes.ISubscriptionCancelRequest = {
      SubscriptionId: subscriptionId,
    };
    const response = await this.instance.post<any>('v1/subscriptionCancelRequest', body);
    return response.status === 200;
  }

  /**
   * Change the quantity of the given subscription
   * @param subscriptionId The subscription id to be cancelled
   * @param quantity The new number of users
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async subscriptionChangeRequest(subscriptionId: string, quantity: number): Promise<boolean> {
    const body: LicenceServerTypes.ISubscriptionChangeRequest = {
      SubscriptionId: subscriptionId,
      PlanQuantity: quantity,
    };
    const response = await this.instance.post<any>('v1/subscriptionChangeRequest', body);
    return response.status === 200;
  }

  /**
   * Invite a user to a subscription with the given role
   * @param subscriptionId The subscription id to invite the user to
   * @param email The email address of the user to be invited
   * @param role The role of the invited user for this subscription
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async subscriptionInviteRequest(
    subscriptionId: string,
    email: string,
    role: string,
    flags: string[]
  ): Promise<boolean> {
    const body: LicenceServerTypes.INewInviteRequest = {
      ResourceType: 'Subscription',
      ResourceId: subscriptionId,
      Email: email,
      Role: role,
      FlagsToAdd: flags,
    };
    const response = await this.instance.post<any>('v1/invite', body);
    return response.status === 204;
  }

  /**
   * Update the permission flags for a consumer of a resource
   * @param resourceId The resource id
   * @param consumerId The consumer id receiving the updated permissions
   * @param flags An array of permission flags with the new permissions
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async updatePermissionFlags(resourceId: string, consumerId: string, flags: string[]): Promise<boolean> {
    const body: LicenceServerTypes.IPermissionSetRequest = {
      ResourceId: resourceId,
      ConsumerId: consumerId,
      Permissions: flags,
    };
    const response = await this.instance.post<any>(`v1/permissions`, body);
    return response.status === 204;
  }

  /**
   * Add permission flags for a given user
   * @param userId The user id receiving the updated permissions
   * @param flags An array of permission flags with the new permissions
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async userPermissionFlagsAdd(userId: string, flags: string[]): Promise<boolean> {
    const body = { Permissions: flags };
    const response = await this.instance.put<any>(`v1/user/${userId}/permission`, body);
    return response.status === 200 || response.status === 204;
  }

  /**
   * Add permission flags for a given user
   * @param userEmail The user email receiving the updated permissions
   * @param flags An array of Trapeze permission flags with the new permissions
   * @param userCan An array of user permission flags with the new permissions
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async addPermissionFlagsByEmail(userEmail: string, flags: string[], userCan: string[]): Promise<boolean> {
    const body = {
      id: '',
      Email: userEmail,
      Mode: 'Add',
      TrapezePermissions: flags,
      userCanPermissions: userCan,
    };
    const response = await this.instance.post<any>(`v1/userPermissions`, body);
    return response.status === 200 || response.status === 204;
  }

  /**
   * Remove a permission flag for a given user
   * @param userId The user id losing the permission
   * @param flag The permission flag to be removed
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async userPermissionFlagRemove(userId: string, flag: string): Promise<boolean> {
    const response = await this.instance.delete<any>(`v1/user/${userId}/permission/${flag}`);
    return response.status === 200 || response.status === 204;
  }

  /**
   * Change the role for the given user on a access controlled item
   * @param itemId The item id to revoke access from
   * @param consumerId The user that is having access revoked
   * @param role The new role for the user
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async updateRole(itemId: string, consumerId: string, role: string): Promise<boolean> {
    const config = { params: { resourceId: itemId } };
    const body: LicenceServerTypes.IRoleUpdateRequest = {
      NewRole: role,
    };
    const response = await this.instance.post<any>(`v1/consumers/${consumerId}`, body, config);
    return response.status === 204;
  }

  /**
   * Update the permission flags for a consumer of a resource
   * @param resourceId The resource id
   * @param consumerId The consumer id receiving the updated permissions
   * @param role The new role for the resource consumer
   * @param flags An array of permission flags with the new permissions
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async updateRolePermissionFlags(
    resourceId: string,
    consumerId: string,
    role: string,
    flags: string[]
  ): Promise<boolean> {
    const config = { params: { resourceId: resourceId } };
    const rbody: LicenceServerTypes.IRoleUpdateRequest = {
      NewRole: role,
    };
    const pbody: LicenceServerTypes.IPermissionSetRequest = {
      ResourceId: resourceId,
      ConsumerId: consumerId,
      Permissions: flags,
    };
    const responses = await axios.all<any>([
      this.instance.post<any>(`v1/permissions`, pbody),
      this.instance.post<any>(`v1/consumers/${consumerId}`, rbody, config),
    ]);
    return responses[0].status === 204 && responses[1].status === 204;
  }

  /**
   * Update Subscription values
   * @param subscriptionObject An ISubscriptionValues object
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async updateSubscriptionValues(subscriptionValues: LicenceServerTypes.ISubscriptionValues): Promise<boolean> {
    const body: LicenceServerTypes.ISubscriptionValues = subscriptionValues;
    const response = await this.instance.post<any>('v1/SubscriptionValues', body);
    return response.status === 200;
  }

  /**
   * Transfer ownership of a stamp or preset to a customer
   * @param resourceId The Id of the stamp or preset
   * @param email The customers email whom you want to transfer to
   * @param type resourse type, either Stamp or Preset
   * @returns A promise resolving to TRUE for success, FALSE otherwise
   */
  public async transferResource(
    resourceId: string,
    resourceIdList: string[],
    email: string,
    type: string,
    copy: boolean
  ): Promise<boolean> {
    const body = {
      TargetEmail: email,
      ResourceId: resourceId,
      ResourceIds: resourceIdList,
      ResourceType: type,
      KeepOriginal: copy,
    };
    const response = await this.instance.patch<any>('v1/Consumers', body);
    return response.status === 200;
  }

  public async trialClaim(trialId: string): Promise<boolean> {
    const body: string = '';
    const response = await this.instance.post<any>(`v1/trials/claim/${trialId}`, body);
    return response.status === 200;
  }

  public async trialApproveDecline(trialId: string, approve: boolean): Promise<boolean> {
    const body: LicenceServerTypes.ITrialApprove = {
      Approve: approve,
    };
    const response = await this.instance.post<any>(`v1/trials/resolve/${trialId}`, body);
    return response.status === 200;
  }

  /**
   *
   * @param value Updating User Prefs
   * @returns
   */
  public async updatePreferences(newPrefs: LicenceServerTypes.IPrefs): Promise<boolean> {
    const body = {
      newClientPrefs: newPrefs,
    };
    const response = await this.instance.post<any>('v1/UserData', body);
    return response.status === 200;
  }
}

export default LicenseServerService;
