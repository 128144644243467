import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, FormControl, FormHelperText, Grid, Link, styled, TextField, Typography } from '@material-ui/core';
import { Button, ButtonTypes } from 'odl-components';
import { useStyles } from './AuthUI.styles';

import Spacer from 'components/Spacer/Spacer';
import FieldLabel from './FieldLabel';

interface Props {
  onPasswordSubmit: (password: string) => void;
  email: string;
  loginError?: string;
  onEditEmail: () => void;
}

const PasswordSection: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [password, setPassword] = useState<string>('');
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);

  const onForgotPasswordClick = () => {
    history.push('/auth/reset');
  };

  const onBackToLoginClick = () => {
    setShowError(false);
    props.onEditEmail();
  };

  const onLoginClick = () => {
    props.onPasswordSubmit(password);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setShowError(false);
  };

  const onPasswordViewToggle = () => {
    setPasswordHide((passwordHide) => !passwordHide);
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onLoginClick();
    }
  };

  useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-password')?.focus();

    if (props.loginError) {
      setShowError(true);
    }
  }, [props.loginError]);

  return (
    <Grid container direction="column" item xs>
      <Spacer y={2} />
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FieldLabel required label={t('Email address')} />
          </Grid>
        </Grid>
        <Spacer y={2} />
        <TextField
          id="textfield-email"
          className={classes.disabledField}
          value={props.email}
          fullWidth
          disabled
          variant="outlined"
          InputProps={{
            startAdornment: <Box className={clsx('icon icon-email-outline', classes.iconSpace)} />,
          }}
        />
      </Grid>
      <Spacer y={4} />
      <FormControl fullWidth>
        <Grid item>
          <Grid container item>
            <FieldLabel required label={t('Password')} />
          </Grid>
          <Spacer y={2} />
          <Grid container item>
            <TextField
              id="textfield-password"
              type={passwordHide ? 'password' : 'text'}
              fullWidth
              variant="outlined"
              placeholder="Password"
              error={props.loginError ? true : false}
              onChange={onPasswordChange}
              onKeyPress={onKeyPress}
              InputProps={{
                endAdornment: (
                  <Box
                    className={clsx(passwordHide ? 'icon icon-eye' : 'icon icon-eye-off', classes.showHide)}
                    onClick={onPasswordViewToggle}
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
        {showError && (
          <FormHelperText id="component-helper-text" error>
            <span className="icon icon-alert odlTextInput__icon" /> {t('Incorrect email or password.')}
          </FormHelperText>
        )}
      </FormControl>

      <Grid item>
        <Spacer y={6} />
        <Button
          text={t('Sign in')}
          type={ButtonTypes.PRIMARY}
          fullWidth
          onClickHandler={onLoginClick}
          disabled={showError}
        />
      </Grid>
      <Spacer y={6} />
      <Grid container direction="row">
        <Grid container item xs justifyContent="flex-start">
          <PointerLink onClick={onForgotPasswordClick}>
            <Typography>{t('Forgot Password')}</Typography>
          </PointerLink>
        </Grid>
        <Grid container item xs justifyContent="flex-end">
          <PointerLink onClick={onBackToLoginClick}>
            <Typography>{t('Back to sign in')}</Typography>
          </PointerLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PasswordSection;

const PointerLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});
