import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { serviceContainer } from 'services/serviceContainer';
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold
import 'styles/objective-icons.css'; // Objective Icons
import 'styles/odl-phone-input.css'; // ODL phone input

// Initialize global i18n instance
serviceContainer.cradle.i18nInitializer.initI18n();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div />}>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
