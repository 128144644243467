import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './LandingOptionTile.style';
import { Grid, Typography } from '@material-ui/core';
import { FontIcon } from 'odl-components';
import Spacer from 'components/Spacer/Spacer';
import clsx from 'clsx';

interface Props {
  name: string;
  iconName: string;
  optionText: string;
  optionText2?: string;
  active: boolean;
  onClick: (optClick: string) => void;
}

const LandingOptionTile: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid className={classes.optionHover} container direction="column" align-items="center" spacing={8}>
      <Grid
        container
        item
        md
        alignContent="center"
        onClick={() => props.onClick(props.name)}
        className={classes.optionGrid}
      >
        <Grid
          item
          container
          direction="row"
          className={clsx('optionBlock', props.active ? classes.activeIcon : classes.inactive)}
          justifyContent="center"
        >
          <FontIcon name={props.iconName} className={props.active ? classes.activeIcon : classes.inactive} />
        </Grid>
        <Spacer y={50} />
        <Grid item container className={classes.optionText} direction="column" justifyContent="center">
          <Grid item xs>
            <Typography align="center" className={props.active ? classes.activeText : classes.inactive}>
              {t(props.optionText)}
            </Typography>
          </Grid>
          {props.optionText2 && (
            <Grid item xs>
              <Typography align="center" className={props.active ? classes.activeText : classes.inactive}>
                {t(props.optionText2)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingOptionTile;
