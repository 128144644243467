import CookieConsent from 'react-cookie-consent';
import { Link, Typography } from '@material-ui/core';

import { useStyles } from './CookieNotification.styles';
import Spacer from 'components/Spacer/Spacer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const CookieNotification = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname !== '/embed') {
    // We don't want this showing in Trapeze desktop
    return (
      <CookieConsent
        location="bottom"
        buttonClasses="btn btn-primary"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        buttonStyle={{
          backgroundColor: '#0266C8',
          color: 'white',
          borderRadius: '3px',
          border: '0px',
        }}
        buttonText={t('Got it').toUpperCase()}
      >
        <Typography>
          {t('We use cookies to ensure that we give you the best experience on our website. View our')}{' '}
          <Link
            className={classes.cookieLink}
            onClick={() => window.open('https://www.objective.com.au/privacy-policy', '_blank', 'noreferrer')}
          >
            {t('Privacy Policy')}
          </Link>{' '}
          {t('to learn more.')}
        </Typography>
        <Spacer x={2} />
      </CookieConsent>
    );
  } else {
    return null;
  }
};

export default CookieNotification;
