import React from 'react';
import { GlobalNavigation, GlobalNavigationItem } from 'odl-components';
import { Box, styled, useTheme } from '@material-ui/core';
import { useMinimizable, useNavItems } from 'components/AppSideNavigation/AppSideNavigation.functions';
import { useStyles } from 'components/AppSideNavigation/AppSideNavigation.styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/useWindowDimensions';

type Props = {
  lightTheme?: boolean;
};

const Overhead = 52;

const AppSideNavigation: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const classes = useStyles();
  const navItems = useNavItems();
  const { isMinimised, toggleMinimised } = useMinimizable();

  return (
    <Box
      component="nav"
      className={classes.root}
      aria-label={t(`Side navigation`)}
      data-testid="AppSideNavigation"
      height={height - Overhead}
    >
      <StyledGlobalNavigation
        a11yId="global-side-navigation"
        ariaLabelCollapse="global-side-navigation-collapse"
        ariaLabelExpand="global-side-navigation-expand"
        ariaLabelNav="global-side-navigation"
        isMinimised={isMinimised}
        onCollapseNavHandler={toggleMinimised}
        onExpandNavHandler={toggleMinimised}
        className={props.lightTheme ? 'light' : 'dark'}
      >
        {navItems.length &&
          navItems.map((navItem, index) => (
            <GlobalNavigationItem
              key={navItem.id}
              a11yId={navItem.id}
              name={navItem.title}
              type={navItem.icon}
              selected={navItem.selected}
              brandColour={theme.palette.objective.brandColors.trapeze.primary}
              className={clsx([classes.navItem])}
              onClickHandler={navItem.onClick}
            />
          ))}
      </StyledGlobalNavigation>
    </Box>
  );
};

export default AppSideNavigation;

const StyledGlobalNavigation = styled(GlobalNavigation)(({ theme }) => ({
  '&.light': {
    backgroundColor: `${theme.palette.objective.light.white} !important`,
    overflowY: 'auto',
    '& .odlGlobalNavigationItem__item': {
      background: theme.palette.objective.light.white,
      '& .icon': {
        color: theme.palette.objective.dark.night,
      },
      '&:hover': {
        background: theme.palette.objective.blue.light,
        '& .icon': {
          color: theme.palette.objective.dark.night,
        },
      },
      '&:focus': {
        background: theme.palette.objective.blue.light,
        '& .icon': {
          color: theme.palette.objective.dark.night,
        },
      },
    },
    '& .odlGlobalNavigation__icon': {
      color: theme.palette.objective.dark.night,
    },
    '& .odlGlobalNavigationItem__textLabel': {
      color: theme.palette.objective.dark.night,
    },
    '& .odlGlobalNavigationItem__selected': {
      background: theme.palette.objective.blue.light,
    },
  },
}));
