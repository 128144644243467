import React, { useEffect, useState } from 'react';

import { useStyles } from './LandingOptions.styles';
import { Container, Grid } from '@material-ui/core';
import LandingOptionTile from 'components/LandingOptionTile/LandingOptionTile';
import { useHistory } from 'react-router-dom';

interface Props {
  hasOnlineModeAccess: boolean;
}

// Dummy translation targets for the i18next parser
// t('Use Trapeze Online')
// t('Go to Cloud Administration')
const options = [
  { name: 'online', icon: 'icon-objective-logo-trapeze', text: 'Use Trapeze Online', url: '/online' },
  { name: 'admin', icon: 'icon-cloud-check', text: 'Go to Cloud Administration', url: '/home' },
];

// Dummy translation targets for the i18next parser
// t('You currently do not have access to Trapeze Online.')
// t('Please contact your Trapeze subscription manager for more information. Click to continue.')
const restrictedOption = {
  name: 'restrictedAccess',
  icon: 'icon-alert-circle-outline',
  text: 'You currently do not have access to Trapeze Online.',
  text2: 'Please contact your Trapeze subscription manager for more information. Click to continue.',
};

const LandingOptions: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeOption, setActiveOption] = useState<string | null>(null);
  const [restricted, setRestricted] = useState<boolean>(false);

  const optionOnClick = (opt: string) => {
    const optObject = options.filter((option) => option.name === opt)[0];
    switch (opt) {
      case 'admin':
        history.push(optObject.url);
        break;
      case 'online':
        if (props.hasOnlineModeAccess) {
          history.push(optObject.url);
        } else {
          setRestricted(true);
          setActiveOption('restrictedAccess');
        }
        break;
      case 'restrictedAccess':
        setRestricted(false);
        setActiveOption('admin');
        break;
      default:
        console.log('No option recieved');
        break;
    }
  };

  useEffect(() => {
    setActiveOption(props.hasOnlineModeAccess ? 'online' : 'admin');
  }, [setActiveOption, props.hasOnlineModeAccess]);

  useEffect(() => {
    if (restricted) {
      const handle = setTimeout(() => {
        setRestricted(false);
        setActiveOption('admin');
      }, 6000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [restricted]);

  return (
    <Container className={classes.root}>
      <Grid container id="landingoptions" direction="row" spacing={8} alignItems="flex-start">
        {!restricted && (
          <React.Fragment>
            {options.map((option, i) => {
              return (
                <Grid item xs key={`option-${i}`} onMouseEnter={() => setActiveOption(option.name)}>
                  <LandingOptionTile
                    name={option.name}
                    iconName={option.icon}
                    optionText={option.text}
                    active={activeOption === option.name}
                    onClick={optionOnClick}
                  />
                </Grid>
              );
            })}
          </React.Fragment>
        )}
        {restricted && (
          <Grid item xs key={`restricted`} onMouseEnter={() => setActiveOption(restrictedOption.name)}>
            <LandingOptionTile
              name={restrictedOption.name}
              iconName={restrictedOption.icon}
              optionText={restrictedOption.text}
              optionText2={restrictedOption.text2}
              active={activeOption === restrictedOption.name}
              onClick={optionOnClick}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LandingOptions;
