import { PopoverOrigin } from '@material-ui/core';

export const getUserCharacter = (str: string) => {
  if (str.length === 0) {
    return 'U';
  }

  return str[0].toUpperCase();
};

export const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
