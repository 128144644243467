import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@material-ui/core';
import { FontIcon } from 'odl-components';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/Spacer/Spacer';
import { useStyles } from './LandingInformation.styles';

// remove dummyData once we have a functioning API call
const dummyData = {
  banner: {
    title: 'Watch the Collaborate 2021 Masterclasses and Customer Stories',
    alt: 'Collaborate link',
    url: 'https://www.objective.com.au/resources/objective-collaborate-2021-recap',
    src: 'https://www.objective.com/assets/content/images/Banners-on-page/Campaigns/collaborate-21-menu.png',
  },
  help: [
    { title: 'Stamping tools', url: 'https://www.objective.com/resources/whats-new-trapeze-automate-your-stamping' },
    {
      title: 'Comparing page changes',
      url: 'https://www.objective.com/resources/measure-and-compare-even-better-than-before',
    },
    { title: 'Calculating volumes in Trapeze', url: 'https://www.objective.com/resources/volume-calculations' },
    {
      title: 'Annotations and Documentation markup tools',
      url: 'https://www.objective.com/resources/tutorial-trapeze-measure-and-calculate-4',
    },
    { title: 'Objective IDP federation guide', url: '' },
    { title: 'All guides and tutorials', url: 'https://nz.objective.com/products/objective-trapeze-professional' },
  ],
  news: [
    { title: 'Trapeze online Launched', notes: '' },
    { title: 'Version 21.10 released. See new features', notes: '' },
    { title: "We're at BOINZ 2022 - stall 6", notes: '' },
    { title: 'Trapeze raises the bar with volume calculations', notes: '' },
  ],
};

function getInfoData() {
  // This is where you would create an API call to get new information

  const filteredData = { banner: dummyData.banner, help: dummyData.help, news: dummyData.news };
  // For now, we will use the dummy data supplied in this file
  return filteredData;
}

const LandingInformation = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = getInfoData();

  function onInfoClick(article: any) {
    // action for the said article
    // either populate a modal information box or new page  with URL
    console.log(`You clicked "${article.title}"`);
    if (article.url && article.url.length > 8) {
      window.open(article.url);
    }
  }

  return (
    <Container>
      <Divider className={classes.divlines} variant="middle" />
      <Spacer y={5} />
      <Grid container justifyContent="space-around" spacing={8}>
        <Grid item xs>
          <Grid container justifyContent="space-between" spacing={8} alignItems="flex-start">
            <Grid item>
              <Box className={classes.bannerContent} onClick={() => onInfoClick(data.banner)}>
                {data && data.banner && (
                  <>
                    <img src={data.banner.src} alt={data.banner.alt} />
                    <Typography className={classes.infoLinks}>{data.banner.title}</Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs className={classes.vertDividers}>
          <Typography className={classes.infoHeader}>
            <FontIcon name="icon-school" className={classes.icon} />
            {t('Help and Guides')}
          </Typography>
          <Spacer y={5} />
          {data.help.map((h: any, i: number) => {
            return (
              <Typography className={classes.infoLinks} key={`helpItem-${i}`} onClick={() => onInfoClick(h)}>
                {h.title}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs>
          <Typography className={classes.infoHeader}>
            <FontIcon name="icon-bullhorn" className={classes.icon} />
            {t('News')}
          </Typography>
          <Spacer y={5} />
          {data.news.map((n: any, i: number) => {
            return (
              <Typography className={classes.infoLinks} key={`newsItem-${i}`} onClick={() => onInfoClick(n)}>
                {n.title}
              </Typography>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingInformation;
