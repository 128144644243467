import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      width: '100%',
    },
    /* Ability to scroll nav with mousewheel if page zoomed in */
    overflowY: 'auto',
    /* hide scrollbar in chrome & edge */
    '&::-webkit-scrollbar': {
      width: '0',
      background: 'transparent',
    },
    /* hide scrollbar in firefox */
    scrollbarWidth: 'none',
  },
  navItem: {
    marginBottom: theme.spacing(2),
    '& .odlGlobalNavigationItem__icon': {
      justifyContent: 'center',
    },
    '& .icon.odlGlobalNavigationItem__icon': {
      marginLeft: '2px',
      marginRight: '14px',
    },
  },
  expendButton: {
    minWidth: 0,
    marginLeft: '6px',
  },
  collapseButton: {
    minWidth: 0,
    marginLeft: '6px',
  },
}));
