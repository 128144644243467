import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainLayout from 'components/MainLayout/MainLayout';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import Spacer from 'components/Spacer/Spacer';
import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';
import LandingPage from 'pages/landing/LandingPage';
import LoginPage from 'components/AuthUI/Login';
import CookieNotification from 'components/CookieNotification/CookieNotification';

// Route-based code splitting
const ChargebeePage = React.lazy(() => import('pages/chargebee/ChargebeePage'));
const HomePage = React.lazy(() => import('pages/home/HomePage'));
const NoMatchPage = React.lazy(() => import('pages/no-match/NoMatchPage'));
const ProfilePage = React.lazy(() => import('pages/profile/ProfilePage'));
const PurchasePage = React.lazy(() => import('pages/purchase/PurchasePage'));
const SignOutPage = React.lazy(() => import('pages/sign-out/SignOutPage'));
const SubscriptionsPage = React.lazy(() => import('pages/subscriptions/SubscriptionsPage'));
const TrialsPage = React.lazy(() => import('pages/trial-admin/TrialAdminPage'));
const CustomersPage = React.lazy(() => import('pages/customer-admin/CustomerAdminPage'));
const StampAdminPage = React.lazy(() => import('pages/stamp-admin/StampAdminPage'));
const GroupAdminPage = React.lazy(() => import('pages/group-admin/GroupAdminPage'));
const PresetAdminPage = React.lazy(() => import('pages/preset-admin/PresetAdminPage'));
const SettingsAdminPage = React.lazy(() => import('pages/settings-admin/SettingsAdminPage'));
const AddOnAdminPage = React.lazy(() => import('pages/addon-admin/AddOnAdminPage'));
const OnlinePage = React.lazy(() => import('pages/online/OnlinePage'));
const EmbedPage = React.lazy(() => import('pages/embed/EmbedPage'));
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const UserComms = React.lazy(() => import('pages/user-comms/UserCommsPage'));

const RouteController: React.FC = () => {
  const { t } = useTranslation();
  const { authenticated } = useStoreSelector(selectAuthenticationState);

  return (
    <Router>
      <React.Suspense
        fallback={
          <MainLayout>
            <Backdrop open={true}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                <CircularProgress size={100} thickness={4} disableShrink />
                <Spacer y={4} />
                {t('Loading...')}
              </Box>
            </Backdrop>
          </MainLayout>
        }
      >
        <Switch>
          <Route path="/signin" component={LoginPage} />
          <Route path="/signout" component={SignOutPage} />
          <Route path="/embed" component={EmbedPage} />
          {!authenticated && <Route path="/auth/*" component={LoginPage} />}
          {!authenticated && <Route path="*" component={LoginPage} />}
          {authenticated && <Route exact path="/" component={LandingPage} />}
          {authenticated && <Route exact path="/online" component={OnlinePage} />}
          {authenticated && (
            <MainLayout>
              <Switch>
                <Route path="/home" component={HomePage} />
                <Route path="/chargebee" component={ChargebeePage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/purchase" component={PurchasePage} />
                <Route path="/subscriptions" component={SubscriptionsPage} />
                <Route path="/stamps" component={StampAdminPage} />
                <Route path="/groups" component={GroupAdminPage} />
                <Route path="/presets" component={PresetAdminPage} />
                <Route path="/settings" component={SettingsAdminPage} />
                <Route path="/add-ons" component={AddOnAdminPage} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/trials" component={TrialsPage} />
                <Route path="/customers" component={CustomersPage} />
                <Route path="/usercomms" component={UserComms} />
                <Route path="*" component={NoMatchPage} />
              </Switch>
            </MainLayout>
          )}
          <Route path="*" component={NoMatchPage} />
        </Switch>
        <CookieNotification />
      </React.Suspense>
    </Router>
  );
};

export default RouteController;
